export const npmPackagesTree = [
	{
		"id": 0,
		"label": "@formkit",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "auto-animate",
				"type": "child",
				"package": "@formkit/auto-animate@1.0.0-pre-alpha.3",
				"version": "1.0.0-pre-alpha.3",
				"require": {
					"key": "@formkit/auto-animate",
					"ver": "^1.0.0-beta.6",
					"type": "gen",
					"desc": "App"
				},
				"parent": "@formkit"
			},
			{
				"id": 1,
				"label": "icons",
				"type": "child",
				"package": "@formkit/icons@0.16.5",
				"version": "0.16.5",
				"require": {
					"key": "@formkit/icons",
					"ver": "^0.16.4",
					"type": "gen",
					"desc": "App"
				},
				"parent": "@formkit"
			},
			{
				"id": 2,
				"label": "themes",
				"type": "child",
				"package": "@formkit/themes@0.16.5",
				"version": "0.16.5",
				"require": {
					"key": "@formkit/themes",
					"ver": "^0.16.4",
					"type": "gen",
					"desc": "App"
				},
				"parent": "@formkit"
			},
			{
				"id": 3,
				"label": "vue",
				"type": "child",
				"package": "@formkit/vue@0.16.5",
				"version": "0.16.5",
				"require": {
					"key": "@formkit/vue",
					"ver": "^0.16.4",
					"type": "gen",
					"desc": "App"
				},
				"parent": "@formkit"
			}
		]
	},
	{
		"id": 1,
		"label": "@fortawesome",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "fontawesome-free",
				"type": "child",
				"package": "@fortawesome/fontawesome-free@6.4.2",
				"version": "6.4.2",
				"require": {
					"key": "@fortawesome/fontawesome-free",
					"ver": "^6.4.0",
					"type": "gen",
					"desc": "App"
				},
				"parent": "@fortawesome"
			}
		]
	},
	{
		"id": 2,
		"label": "@googlemaps",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "markerclusterer",
				"type": "child",
				"package": "@googlemaps/markerclusterer@2.4.0",
				"version": "2.4.0",
				"require": {
					"key": "@googlemaps/markerclusterer",
					"ver": "^2.0.15",
					"type": "gen",
					"desc": "App"
				},
				"parent": "@googlemaps"
			}
		]
	},
	{
		"id": 3,
		"label": "@inertiajs",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "inertia-vue3",
				"type": "child",
				"package": "@inertiajs/inertia-vue3@0.6.0",
				"version": "0.6.0",
				"require": {
					"key": "@inertiajs/inertia-vue3",
					"ver": "^0.6.0",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "@inertiajs"
			},
			{
				"id": 1,
				"label": "inertia",
				"type": "child",
				"package": "@inertiajs/inertia@0.11.1",
				"version": "0.11.1",
				"require": {
					"key": "@inertiajs/inertia",
					"ver": "^0.11.0",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "@inertiajs"
			},
			{
				"id": 2,
				"label": "progress",
				"type": "child",
				"package": "@inertiajs/progress@0.2.7",
				"version": "0.2.7",
				"require": {
					"key": "@inertiajs/progress",
					"ver": "^0.2.7",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "@inertiajs"
			},
			{
				"id": 3,
				"label": "server",
				"type": "child",
				"package": "@inertiajs/server@0.1.0",
				"version": "0.1.0",
				"require": {
					"key": "@inertiajs/server",
					"ver": "^0.1.0",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "@inertiajs"
			}
		]
	},
	{
		"id": 4,
		"label": "@tailwindcss",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "forms",
				"type": "child",
				"package": "@tailwindcss/forms@0.5.5",
				"version": "0.5.5",
				"require": {
					"key": "@tailwindcss/forms",
					"ver": "^0.5.2",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "@tailwindcss"
			},
			{
				"id": 1,
				"label": "typography",
				"type": "child",
				"package": "@tailwindcss/typography@0.5.9",
				"version": "0.5.9",
				"require": {
					"key": "@tailwindcss/typography",
					"ver": "^0.5.2",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "@tailwindcss"
			}
		]
	},
	{
		"id": 5,
		"label": "@vitejs",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "plugin-vue",
				"type": "child",
				"package": "@vitejs/plugin-vue@3.2.0",
				"version": "3.2.0",
				"require": {
					"key": "@vitejs/plugin-vue",
					"ver": "^3.0.0",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "@vitejs"
			}
		]
	},
	{
		"id": 6,
		"label": "@vue",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "server-renderer",
				"type": "child",
				"package": "@vue/server-renderer@3.3.4",
				"version": "3.3.4",
				"require": {
					"key": "@vue/server-renderer",
					"ver": "^3.2.31",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "@vue"
			}
		]
	},
	{
		"id": 7,
		"label": "ag-grid-community@30.1.0",
		"type": "parent",
		"package": "ag-grid-community",
		"version": "30.1.0",
		"require": {
			"key": "ag-grid-community",
			"ver": "^30.0.3",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 8,
		"label": "ag-grid-vue3@30.1.0",
		"type": "parent",
		"package": "ag-grid-vue3",
		"version": "30.1.0",
		"require": {
			"key": "ag-grid-vue3",
			"ver": "^30.0.3",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 9,
		"label": "ansi_up@6.0.2",
		"type": "parent",
		"package": "ansi_up",
		"version": "6.0.2",
		"require": {
			"key": "ansi_up",
			"ver": "^6.0.2",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 10,
		"label": "autoprefixer@10.4.15",
		"type": "parent",
		"package": "autoprefixer",
		"version": "10.4.15",
		"require": {
			"key": "autoprefixer",
			"ver": "^10.4.13",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 11,
		"label": "axios-auth-refresh@3.3.6",
		"type": "parent",
		"package": "axios-auth-refresh",
		"version": "3.3.6",
		"require": {
			"key": "axios-auth-refresh",
			"ver": "^3.3.6",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 12,
		"label": "axios@1.4.0",
		"type": "parent",
		"package": "axios",
		"version": "1.4.0",
		"require": {
			"key": "axios",
			"ver": "^1.1.3",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 13,
		"label": "chart.js@3.9.1",
		"type": "parent",
		"package": "chart.js",
		"version": "3.9.1",
		"require": {
			"key": "chart.js",
			"ver": "^3.9.1",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 14,
		"label": "chartjs-adapter-luxon@1.3.1",
		"type": "parent",
		"package": "chartjs-adapter-luxon",
		"version": "1.3.1",
		"require": {
			"key": "chartjs-adapter-luxon",
			"ver": "^1.3.1",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 15,
		"label": "chartjs-plugin-datalabels@2.2.0",
		"type": "parent",
		"package": "chartjs-plugin-datalabels",
		"version": "2.2.0",
		"require": {
			"key": "chartjs-plugin-datalabels",
			"ver": "^2.2.0",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 16,
		"label": "date-fns@2.30.0",
		"type": "parent",
		"package": "date-fns",
		"version": "2.30.0",
		"require": {
			"key": "date-fns",
			"ver": "^2.29.3",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 17,
		"label": "element-plus@2.3.10",
		"type": "parent",
		"package": "element-plus",
		"version": "2.3.10",
		"require": {
			"key": "element-plus",
			"ver": "^2.2.28",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 18,
		"label": "flatted@3.2.7",
		"type": "parent",
		"package": "flatted",
		"version": "3.2.7",
		"require": {
			"key": "flatted",
			"ver": "^3.2.7",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 19,
		"label": "js-cookie@3.0.5",
		"type": "parent",
		"package": "js-cookie",
		"version": "3.0.5",
		"require": {
			"key": "js-cookie",
			"ver": "^3.0.1",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 20,
		"label": "laravel-echo@1.15.2",
		"type": "parent",
		"package": "laravel-echo",
		"version": "1.15.2",
		"require": {
			"key": "laravel-echo",
			"ver": "^1.15.0",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 21,
		"label": "laravel-vite-plugin@0.6.1",
		"type": "parent",
		"package": "laravel-vite-plugin",
		"version": "0.6.1",
		"require": {
			"key": "laravel-vite-plugin",
			"ver": "^0.6.0",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 22,
		"label": "localforage@1.10.0",
		"type": "parent",
		"package": "localforage",
		"version": "1.10.0",
		"require": {
			"key": "localforage",
			"ver": "^1.10.0",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 23,
		"label": "lodash@4.17.21",
		"type": "parent",
		"package": "lodash",
		"version": "4.17.21",
		"require": {
			"key": "lodash",
			"ver": "^4.17.19",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 24,
		"label": "luxon@3.4.1",
		"type": "parent",
		"package": "luxon",
		"version": "3.4.1",
		"require": {
			"key": "luxon",
			"ver": "^3.3.0",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 25,
		"label": "moment-timezone@0.5.43",
		"type": "parent",
		"package": "moment-timezone",
		"version": "0.5.43",
		"require": {
			"key": "moment-timezone",
			"ver": "^0.5.40",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 26,
		"label": "numeral@2.0.6",
		"type": "parent",
		"package": "numeral",
		"version": "2.0.6",
		"require": {
			"key": "numeral",
			"ver": "^2.0.6",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 27,
		"label": "papaparse@5.4.1",
		"type": "parent",
		"package": "papaparse",
		"version": "5.4.1",
		"require": {
			"key": "papaparse",
			"ver": "^5.4.1",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 28,
		"label": "postcss@8.4.28",
		"type": "parent",
		"package": "postcss",
		"version": "8.4.28",
		"require": {
			"key": "postcss",
			"ver": "^8.4.14",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 29,
		"label": "pouchdb-utils@8.0.1",
		"type": "parent",
		"package": "pouchdb-utils",
		"version": "8.0.1",
		"require": {
			"key": "pouchdb-utils",
			"ver": "8.0.1",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 30,
		"label": "pusher-js@8.3.0",
		"type": "parent",
		"package": "pusher-js",
		"version": "8.3.0",
		"require": {
			"key": "pusher-js",
			"ver": "^8.0.1",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 31,
		"label": "sass@1.66.1",
		"type": "parent",
		"package": "sass",
		"version": "1.66.1",
		"require": {
			"key": "sass",
			"ver": "^1.58.0",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 32,
		"label": "unplugin-auto-import@0.12.2",
		"type": "parent",
		"package": "unplugin-auto-import",
		"version": "0.12.2",
		"require": {
			"key": "unplugin-auto-import",
			"ver": "^0.12.1",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 33,
		"label": "unplugin-vue-components@0.22.12",
		"type": "parent",
		"package": "unplugin-vue-components",
		"version": "0.22.12",
		"require": {
			"key": "unplugin-vue-components",
			"ver": "^0.22.12",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 34,
		"label": "vite-plugin-pwa@0.14.7",
		"type": "parent",
		"package": "vite-plugin-pwa",
		"version": "0.14.7",
		"require": {
			"key": "vite-plugin-pwa",
			"ver": "^0.14.1",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 35,
		"label": "vite@3.2.7",
		"type": "parent",
		"package": "vite",
		"version": "3.2.7",
		"require": {
			"key": "vite",
			"ver": "^3.2.7",
			"type": "dev",
			"desc": "Dev"
		},
		"children": []
	},
	{
		"id": 36,
		"label": "vue-chart-3@3.1.8",
		"type": "parent",
		"package": "vue-chart-3",
		"version": "3.1.8",
		"require": {
			"key": "vue-chart-3",
			"ver": "^3.1.8",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 37,
		"label": "vue-chartkick@1.1.0",
		"type": "parent",
		"package": "vue-chartkick",
		"version": "1.1.0",
		"require": {
			"key": "vue-chartkick",
			"ver": "^1.1.0",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 38,
		"label": "vue-clipboard3@2.0.0",
		"type": "parent",
		"package": "vue-clipboard3",
		"version": "2.0.0",
		"require": {
			"key": "vue-clipboard3",
			"ver": "^2.0.0",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 39,
		"label": "vue-ellipse-progress@2.1.1",
		"type": "parent",
		"package": "vue-ellipse-progress",
		"version": "2.1.1",
		"require": {
			"key": "vue-ellipse-progress",
			"ver": "^2.1.1",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 40,
		"label": "vue-google-maps-community-fork@0.2.3",
		"type": "parent",
		"package": "vue-google-maps-community-fork",
		"version": "0.2.3",
		"require": {
			"key": "vue-google-maps-community-fork",
			"ver": "^0.2.2",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 41,
		"label": "vue-multiselect@3.0.0-beta.2",
		"type": "parent",
		"package": "vue-multiselect",
		"version": "3.0.0-beta.2",
		"require": {
			"key": "vue-multiselect",
			"ver": "^3.0.0-beta.1",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 42,
		"label": "vue3-toastify@0.1.12",
		"type": "parent",
		"package": "vue3-toastify",
		"version": "0.1.12",
		"require": {
			"key": "vue3-toastify",
			"ver": "^0.1.11",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 43,
		"label": "vue3-unicorn-log@1.0.0",
		"type": "parent",
		"package": "vue3-unicorn-log",
		"version": "1.0.0",
		"require": {
			"key": "vue3-unicorn-log",
			"ver": "^1.0.0",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 44,
		"label": "vuex-composition-helpers@2.0.3",
		"type": "parent",
		"package": "vuex-composition-helpers",
		"version": "2.0.3",
		"require": {
			"key": "vuex-composition-helpers",
			"ver": "^2.0.3",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 45,
		"label": "vuex-persist@3.1.3",
		"type": "parent",
		"package": "vuex-persist",
		"version": "3.1.3",
		"require": {
			"key": "vuex-persist",
			"ver": "^3.1.3",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	},
	{
		"id": 46,
		"label": "vuex@4.1.0",
		"type": "parent",
		"package": "vuex",
		"version": "4.1.0",
		"require": {
			"key": "vuex",
			"ver": "^4.1.0",
			"type": "gen",
			"desc": "App"
		},
		"children": []
	}
];
