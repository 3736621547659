import axios from "axios";

const locURL = window.location;
const appURL = locURL.protocol + "//" + locURL.host;

const axConfig = {
	baseURL: appURL + "/api/dash/vw",
	headers: {
		"Content-type": "application/json"
	},
	withCredentials: true,
	xsrfCookieName: 'XSRF-TOKEN',
	xsrfHeaderName: 'X-XSRF-TOKEN',
};

export const HTTP = axios.create(axConfig);


