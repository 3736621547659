//import { date } from '@formkit/icons';
import { NowDashStateDataService as Items } from './NowDashStateDataService';
import { NowDashStateAnalyticsService as GoogleDataAPI } from './NowDashStateAnalyticsService';
import {
	SET_ANALYTICS_READY,
	SET_DASH_DATA,
	SET_DASH_READY,
	SET_RANK_DATA,
	SET_RANK_READY,
	SET_KIOSK_DATA,
	SET_KIOSK_COUNTS,
	SET_KIOSK_MAP_MARKERS,
	SET_KIOSK_MAP_HEAT_DATA,
	SET_KIOSK_MAP_HEAT_OPTIONS,
	SET_KIOSK_MAP_INFO_WINDOWS,
	SET_DEALER_DETAILS,
	SET_DEALER_TABLE_DATA,
	SET_DEALERS_GROUPED_BY_STATE,
	SET_DEALERS_KEYED_BY_STATE,
	SET_VW_STATE_LIST,
	SET_VW_STATE_COUNTS,
	SET_VW_AREA_LIST,
	SET_VW_AREA_OPTIONS,
	SET_VW_REGION_LIST,
	SET_VW_REGION_OPTIONS,
	SET_VW_STATES_SELECTED_CURRENT,
	SET_VW_STATES_SELECTED_LIVE,
	SET_VW_AREAS_SELECTED_CURRENT,
	SET_VW_AREAS_SELECTED_LIVE,
	SET_VW_REGIONS_SELECTED_CURRENT,
	SET_VW_REGIONS_SELECTED_LIVE,
	SET_DATA_FILTER_MODEL_CURRENT,
	SET_DATA_FILTER_MODEL_LIVE,
	SET_DATA_ROWS_SELECTED_CURRENT,
	SET_DATA_ROWS_SELECTED_LIVE,
	SET_DATA_NODES_SELECTED_CURRENT,
	SET_DATA_NODES_SELECTED_LIVE,
	SET_DATA_ROWS_FILTERED_CURRENT,
	SET_DATA_ROWS_FILTERED_LIVE,
	SET_FILTER_DATES_SELECTED,
	SET_FILTER_ACCOUNTS_SELECTED,
	SET_IS_ANALYTICS_DATA_DIRTY,
	SET_NOW_DASH_TOOLTIPS,
	SET_NOW_DASH_DEFAULT_TOOLTIP,
	SET_HEADERS,
	SET_PAGINATION,
	SET_ITEMS,
	PATCH_ITEM,
	DELETE_ITEM,
	CREATE_ITEM,
	SET_SEARCH,
	SET_LOADING,
	SET_GOOGLE_DATA_EXPORT_NAME,
	SET_GOOGLE_DATA,
	SET_DEALER_PAGE_VIEWS,
	SET_DEALER_PAGE_VIEWS_COL_DEFS,
	SET_GOOGLE_DATA_DEALERS,
	SET_AG_GOOGLE_DATA_COL_DEFS,
	SET_SHOW_GOOGLE_DATA_MODAL,
	SET_GOOGLE_DATA_DEALER_FILTERS,
	CLEAR_GOOGLE_DATA_DEALER_FILTERS,
} from './NowDashStateMutations';

const consoleLogTitle = '[NowDashState.js] - ';

const NowDashState = {
	namespaced: true,
	state: {
		currentDataFilterModel: {},
		liveDataFilterModel: {},
		currentDataRowsSelected: [],
		liveDataRowsSelected: [],
		currentDataNodesSelected: [],
		liveDataNodesSelected: [],
		currentDataRowsFiltered: [],
		liveDataRowsFiltered: [],
		currentAreasSelected: [],
		liveAreasSelected: [],
		currentRegionsSelected: [],
		liveRegionsSelected: [],
		currentStatesSelected: [],
		liveStatesSelected: [],
		currentAccountsSelected: [],
		liveAccountsSelected: [],
		analyticsDataReady: false,
		dashboardData: {},
		dashDataReady: false,
		rankAnalytics: {},
		rankDataReady: false,
		kioskData: {},
		kioskCounts: {},
		kioskMapMarkers: [],
		kioskMapHeatData: [],
		kioskMapHeatOptions: {},
		kioskMapInfoWindows: [],
		dealerDetails: [],
		dealerTableData: {},
		dealersGroupedByState: [],
		dealersKeyedByState: {},
		vwStateList: [],
		vwStateCounts: {},
		vwAreaList: [],
		vwAreaOptions: [],
		vwRegionList: [],
		vwRegionOptions: [],
		filterDatesSelected: [],
		filterAccountsSelected: [],
		isAnalyticsDataDirty: false,
		nowDashTooltips: {},
		googleData: [],
		dealerPageViews: [],
		agDealerPageViewsColDefs: [],
		googleDataExportName: '',
		agGoogleDataColDefs: [],
		showGoogleDataModal: false,
		googleDataDealers: [],
		googleDataDealerFilters: [],
		nowDashTooltipDefault: {
			tooltip_header_text_bold: 'Dashboard',
			tooltip_header_text_normal: 'Info Tip',
			tooltip_title_text_bold: 'Tooltip',
			tooltip_title_text_normal: 'Title',
			tooltip_text: 'Tooltip informational text that needs to be entered into the database.',
			tooltip_icon: 'fa-book-open-reader',
		},
		checkedRankedPages: [
			'Explore VW',
			'Charging Stations',
			'Electrify America',
			'Electrify Home',
			'Accessory Builder',
			'Explore Dealer',
			'Know Your VW',
			'ID.4 Walkaround',
			'Media Videos',
			'Social Media',
			// 'My Volkswagen',
		],
		headers: [
			{
				title: 'Avatar',
				align: 'center',
				sortable: false,
				key: 'profile_photo_url',
			},
			{
				title: 'Name',
				align: 'start',
				sortable: true,
				key: 'name',
			},
			{
				title: 'Email',
				align: 'start',
				sortable: true,
				key: 'email',
			},
			{
				title: 'Current Team',
				align: 'start',
				sortable: false,
				key: 'team.name',
			},
			{
				title: 'Email Verified',
				align: 'start',
				sortable: true,
				key: 'email_verified_at',
			},
			{
				title: 'Enabled 2FA',
				align: 'start',
				sortable: true,
				key: 'two_factor_confirmed_at',
			},
			{
				title: 'Last Updated',
				align: 'start',
				sortable: true,
				key: 'updated_at',
			},
			{
				title: 'Created',
				align: 'start',
				sortable: true,
				key: 'created_at',
			},
		],
		pagination: {
			page: 1,
			from: 1,
			to: 1,
			rowsPerPage: 5,
			sortBy: {
				key: 'created_at',
				order: 'desc',
			},
			totalItems: 0,
			totalPages: 0,
			rowsPerPageItems: [5, 10, 15, 20, 25],
		},
		items: [],
		search: '',
		loading: true,
	},
	getters: {
		checkedRankedPages(state) {
			state.checkedRankedPages;
		},
		currentAccountsSelected(state) {
			state.currentAccountsSelected;
		},
		liveAccountsSelected(state) {
			state.liveAccountsSelected;
		},
		dashboardData(state) {
			state.dashboardData;
		},
		dashDataReady(state) {
			state.dashDataReady;
		},
		rankAnalytics(state) {
			state.rankAnalytics;
		},
		rankDataReady(state) {
			state.rankDataReady;
		},
		nowDashTooltips(state) {
			state.nowDashTooltips;
		},
		nowDashTooltipDefault(state) {
			state.nowDashTooltipDefault;
		},
		headers(state) {
			return state.headers;
		},
		loading(state) {
			return state.loading;
		},
		pagination(state) {
			return state.pagination;
		},
		items(state) {
			return state.items;
		},
		search(state) {
			return state.search;
		},
	},
	actions: {
		async updateAnalytics({ commit, dispatch, state }) {
			const functionLogTitle = consoleLogTitle + '[actions][updateAnalytics]: ';
			console.log(functionLogTitle + 'Executing function and building query parameters from state filters...');
			const fillEmptyDate60DayDefault = () => {
				const end = new Date();
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 2);
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 62);
				const earliestStart = new Date('2023-01-26');
				earliestStart.setTime(earliestStart.getTime()); // - 3600 * 1000 * 24 * 1);
				let calcStart = start;
				if (start < earliestStart) {
					calcStart = earliestStart;
				}
				let formedStart = new Date(calcStart.toLocaleDateString('en-US').replace(/\//g, '-') + ' 00:00:00');
				let formedEnd = new Date(end.toLocaleDateString('en-US').replace(/\//g, '-') + ' 23:59:59');
				return [formedStart, formedEnd];
			};
			console.log(functionLogTitle + 'Check if date filtering parameter is valid...');
			let dateFilterIsGood = false;
			if (Array.isArray(state.filterDatesSelected)) {
				if (state.filterDatesSelected.length === 2) {
					console.log(
						functionLogTitle +
							'Date filtering parameter is valid, isArray and has length of 2 items... Current value:',
						state.filterDatesSelected,
					);
					dateFilterIsGood = true;
				}
			}
			if (dateFilterIsGood !== true) {
				console.log(
					functionLogTitle +
						'Date filtering parameter is not valid, either not array or has length of less than 2 items... Current value:',
					state.filterDatesSelected,
				);
				const fillEmptyDateFilter = fillEmptyDate60DayDefault();
				console.log(
					functionLogTitle +
						'Setting date filtering parameter to last 60 days, to increase performance, instead of all available dates... New value:',
					fillEmptyDateFilter,
				);
				commit(SET_FILTER_DATES_SELECTED, fillEmptyDateFilter);
			}

			commit(SET_DASH_READY, false);
			commit(SET_RANK_READY, false);
			commit(SET_LOADING, true);

			let filteredByDate = false;
			let dateFilters = state.filterDatesSelected;
			if (dateFilters.length === 2) {
				filteredByDate = true;
			}
			const accountFilters = state.filterAccountsSelected;

			const builtQueryParams = {
				accounts: accountFilters,
				pages: state.checkedRankedPages,
			};

			if (filteredByDate === true) {
				builtQueryParams.dateStart = dateFilters[0].toLocaleDateString('en-US').replace(/\//g, '-');
				builtQueryParams.dateEnd = dateFilters[1].toLocaleDateString('en-US').replace(/\//g, '-');
			}
			console.log(functionLogTitle + 'Firing AJAX call to retrieve Analytics...');
			console.log(functionLogTitle + '(filteredByDate) variable for AJAX: ' + filteredByDate);
			console.log(functionLogTitle + '(builtQueryParams) variable for AJAX:', builtQueryParams);
			if (filteredByDate === true) {
				console.log(
					functionLogTitle + '(filteredByDate) is true, additional parameters passed...' + filteredByDate,
				);
				console.log(
					functionLogTitle + '(builtQueryParams.dateStart) variable for AJAX: ' + builtQueryParams.dateStart,
				);
				console.log(
					functionLogTitle + '(builtQueryParams.dateEnd) variable for AJAX: ' + builtQueryParams.dateEnd,
				);
			}
			console.log(functionLogTitle + '(builtQueryParams.accounts) variable for AJAX:', builtQueryParams.accounts);
			console.log(functionLogTitle + '(builtQueryParams.pages) variable for AJAX:', builtQueryParams.pages);
			await Items.retrieve(filteredByDate, builtQueryParams)
				.then((items) => {
					console.log(
						functionLogTitle + 'Received response for AJAX call to retrieve analytics, (items) returned:',
						items,
					);
					dispatch('updateStoreData', items);
				})
				.catch((error) => {
					console.error(functionLogTitle + 'Encountered AJAX ERROR for call to retrieve analytics...');
					console.error(functionLogTitle + '(error) response returned:', error);
				});
			commit(SET_IS_ANALYTICS_DATA_DIRTY, false);
			commit(SET_LOADING, false);
			commit(SET_DASH_READY, true);
		},
		async updateGoogleData({ commit, state }, dates) {
			let numOfDays = 0;
			let columns = [];
			let dealerPageViewsColumns = [];

			let colDefs = [];
			let agDealerPageViewsColDefs = [];

			if (dates.length === 2) {
				const dateStart = new Date(dates[0]);
				const dateEnd = new Date(dates[1]);

				const timeDifference = dateEnd.getTime() - dateStart.getTime();

				let localeFormat = { month: 'short', day: '2-digit', year: 'numeric' };
				let fileTitle = '_Custom_Analytics';

				numOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

				if (numOfDays >= 365) {
					localeFormat = { month: 'short', year: 'numeric' };
				}

				let formattedDateStart = dateStart.toLocaleDateString('en-US', localeFormat).replace(/[, ]/g, '');
				let formattedDateEnd = dateEnd.toLocaleDateString('en-US', localeFormat).replace(/[, ]/g, '');

				const exportName = 'VW_' + formattedDateStart + '_to_' + formattedDateEnd + fileTitle;
				commit('SET_GOOGLE_DATA_EXPORT_NAME', exportName);

				// Less than or equal 1 year
				if (numOfDays < 365) {
					for (
						let currentDate = dateStart;
						currentDate <= dateEnd;
						currentDate.setDate(currentDate.getDate() + 1)
					) {
						let name = currentDate.toLocaleDateString('en-US', localeFormat);
						let field = name.replace(/,|\s/g, '');

						let col = {
							headerName: name,
							field: field,
						};

						let dealerPageViewsCol = {
							headerName: name,
							field: field,
						}

						columns.push(col);
						dealerPageViewsColumns.push(dealerPageViewsCol);
					}
				} else {
					const months = [];
					const currentDate = new Date(dates[0]);

					while (currentDate <= dateEnd) {
						const currentMonth = currentDate.toLocaleDateString('en-US', localeFormat);
						months.push(currentMonth);

						currentDate.setMonth(currentDate.getMonth() + 1);

						let col = {
							headerName: currentMonth,
							field: currentMonth.replace(/ /g, ''),
						};

						let dealerPageViewsCol = {
							headerName: currentMonth,
							field: currentMonth.replace(/ /g, ''),
						}

						columns.push(col);
						dealerPageViewsColumns.push(dealerPageViewsCol);
					}
				}
			}

			colDefs.push(
				{ headerName: 'PAGE TITLE AND SCREEN CLASS', field: 'title', width: 300 },
				{ headerName: 'Views and Average Engagement Time [Views - mm:ss]', children: [...columns] },
			);

			agDealerPageViewsColDefs.push(
				{
					headerName: 'Dealers', children: [
						{ headerName: 'Name',     field: 'dealer_name', width: 300 },
						{ headerName: 'OEM Code', field: 'oem_code' },
					]
				},
				{ headerName: 'Page Views', children: [...dealerPageViewsColumns] }
			)

			let params = {};

			params.dateStart = dates[0].toLocaleDateString('en-US').replace(/\//g, '-');
			params.dateEnd = dates[1].toLocaleDateString('en-US').replace(/\//g, '-');

			if (state.googleDataDealerFilters && state.googleDataDealerFilters.length > 0) {
				params.accounts = state.googleDataDealerFilters;
			}

			const result = [];

			await GoogleDataAPI.retrieve(true, params).then((items) => {
				const dates = Object.keys(items['data']);
				const pages = Object.values(items['pages']);

				const dealerPageViews = items['dealerPageViews'];

				pages.forEach((page, index) => {
					result[index] = { title: page };

					dates.forEach((date) => {
						if (items['data'][date][page]) {
							result[index][date] = items['data'][date][page];
						} else {
							result[index][date] = '';
						}
					});
				});

				commit('SET_GOOGLE_DATA', result);
				commit(
					'SET_GOOGLE_DATA_DEALERS',
					Object.keys(items['dealers']).map((key) => items['dealers'][key]),
				);

				commit('SET_DEALER_PAGE_VIEWS', dealerPageViews);

				console.log('Update Custom Data:' + params.dateStart + '-' + params.dateEnd);
			})
			.catch((error) => {
				console.log('Custom analytics data export error!');
				console.log(error);
			});

			commit('SET_AG_GOOGLE_DATA_COL_DEFS', colDefs);
			commit('SET_DEALER_PAGE_VIEWS_COL_DEFS', agDealerPageViewsColDefs);

			console.log('State - Google Def Cols:');
			console.log(state.agGoogleDataColDefs);

			console.log('State - Google Data:');
			console.log(state.googleData);

			console.log('State - Dealer Page Views');
			console.log(state.dealerPageViews);

			console.log('State - Dealer Page Views Col Defs');
			console.log(state.agDealerPageViewsColDefs);
		},
		async updateStoreData({ commit }, items) {
			if (items) {
				if (typeof items === 'object' || items instanceof Object) {
					commit(SET_DASH_DATA, items);
					if (items.rankAnalytics) {
						commit(SET_RANK_DATA, items.rankAnalytics);
						commit(SET_RANK_READY, true);
					}
					commit(SET_KIOSK_DATA, items);
					commit(SET_KIOSK_COUNTS, items);
					commit(SET_KIOSK_MAP_MARKERS, items);
					commit(SET_KIOSK_MAP_HEAT_DATA, items);
					commit(SET_KIOSK_MAP_HEAT_OPTIONS, items);
					commit(SET_KIOSK_MAP_INFO_WINDOWS, items);
					commit(SET_DEALER_DETAILS, items);
					commit(SET_DEALER_TABLE_DATA, items);
					commit(SET_DEALERS_GROUPED_BY_STATE, items);
					commit(SET_DEALERS_KEYED_BY_STATE, items);
					commit(SET_VW_STATE_LIST, items);
					commit(SET_VW_STATE_COUNTS, items);
					commit(SET_VW_AREA_LIST, items);
					commit(SET_VW_AREA_OPTIONS, items);
					commit(SET_VW_REGION_LIST, items);
					commit(SET_VW_REGION_OPTIONS, items);
					commit(SET_NOW_DASH_TOOLTIPS, items);
					commit(SET_NOW_DASH_DEFAULT_TOOLTIP, items);
				}
			}
		},
		async setLiveStatesSelected({ commit }, filterPayload) {
			commit(SET_VW_STATES_SELECTED_LIVE, filterPayload);
		},
		async setCurrentStatesSelected({ commit }, filterPayload) {
			commit(SET_VW_STATES_SELECTED_CURRENT, filterPayload);
		},
		async revertLiveStatesSelected({ commit, state }) {
			commit(SET_VW_STATES_SELECTED_LIVE, state.currentStatesSelected);
		},
		async applyLiveStatesSelected({ commit, state }) {
			commit(SET_VW_STATES_SELECTED_CURRENT, state.liveStatesSelected);
		},
		async setLiveAreasSelected({ commit }, filterPayload) {
			commit(SET_VW_AREAS_SELECTED_LIVE, filterPayload);
		},
		async setCurrentAreasSelected({ commit }, filterPayload) {
			commit(SET_VW_AREAS_SELECTED_CURRENT, filterPayload);
		},
		async revertLiveAreasSelected({ commit, state }) {
			commit(SET_VW_AREAS_SELECTED_LIVE, state.currentAreasSelected);
		},
		async applyLiveAreasSelected({ commit, state }) {
			commit(SET_VW_AREAS_SELECTED_CURRENT, state.liveAreasSelected);
		},
		async setLiveRegionsSelected({ commit }, filterPayload) {
			commit(SET_VW_REGIONS_SELECTED_LIVE, filterPayload);
		},
		async setCurrentRegionsSelected({ commit }, filterPayload) {
			commit(SET_VW_REGIONS_SELECTED_CURRENT, filterPayload);
		},
		async revertLiveRegionsSelected({ commit, state }) {
			commit(SET_VW_REGIONS_SELECTED_LIVE, state.currentRegionsSelected);
		},
		async applyLiveRegionsSelected({ commit, state }) {
			commit(SET_VW_REGIONS_SELECTED_CURRENT, state.liveRegionsSelected);
		},
		async setLiveDataFilterModel({ commit }, filterPayload) {
			commit(SET_DATA_FILTER_MODEL_LIVE, filterPayload);
		},
		async setCurrentDataFilterModel({ commit }, filterPayload) {
			commit(SET_DATA_FILTER_MODEL_CURRENT, filterPayload);
		},
		async revertLiveDataFilterModel({ commit, state }) {
			commit(SET_DATA_FILTER_MODEL_LIVE, state.currentDataFilterModel);
		},
		async applyLiveDataFilterModel({ commit, state }) {
			commit(SET_DATA_FILTER_MODEL_CURRENT, state.liveDataFilterModel);
		},
		async clearDataFilterModel({ commit }) {
			commit(SET_DATA_FILTER_MODEL_LIVE, {});
			commit(SET_DATA_FILTER_MODEL_CURRENT, {});
		},
		async setLiveDataRowsSelected({ commit }, filterPayload) {
			commit(SET_DATA_ROWS_SELECTED_LIVE, filterPayload);
		},
		async setCurrentDataRowsSelected({ commit }, filterPayload) {
			commit(SET_DATA_ROWS_SELECTED_CURRENT, filterPayload);
		},
		async revertLiveDataRowsSelected({ commit, state }) {
			commit(SET_DATA_ROWS_SELECTED_LIVE, state.currentDataRowsSelected);
		},
		async applyLiveDataRowsSelected({ commit, state }) {
			commit(SET_DATA_ROWS_SELECTED_CURRENT, state.liveDataRowsSelected);
		},
		async clearDataRowsSelected({ commit }) {
			commit(SET_DATA_ROWS_SELECTED_LIVE, []);
			commit(SET_DATA_ROWS_SELECTED_CURRENT, []);
		},
		async setLiveDataNodesSelected({ commit }, filterPayload) {
			commit(SET_DATA_NODES_SELECTED_LIVE, filterPayload);
		},
		async setCurrentDataNodesSelected({ commit }, filterPayload) {
			commit(SET_DATA_NODES_SELECTED_CURRENT, filterPayload);
		},
		async revertLiveDataNodesSelected({ commit, state }) {
			commit(SET_DATA_NODES_SELECTED_LIVE, state.currentDataNodesSelected);
		},
		async applyLiveDataNodesSelected({ commit, state }) {
			commit(SET_DATA_NODES_SELECTED_CURRENT, state.liveDataNodesSelected);
		},
		async clearDataNodesSelected({ commit }) {
			commit(SET_DATA_NODES_SELECTED_LIVE, []);
			commit(SET_DATA_NODES_SELECTED_CURRENT, []);
		},
		async setLiveDataRowsFiltered({ commit }, filterPayload) {
			commit(SET_DATA_ROWS_FILTERED_LIVE, filterPayload);
		},
		async setCurrentDataRowsFiltered({ commit }, filterPayload) {
			commit(SET_DATA_ROWS_FILTERED_CURRENT, filterPayload);
		},
		async revertLiveDataRowsFiltered({ commit, state }) {
			commit(SET_DATA_ROWS_FILTERED_LIVE, state.currentDataRowsFiltered);
		},
		async applyLiveDataRowsFiltered({ commit, state }) {
			commit(SET_DATA_ROWS_FILTERED_CURRENT, state.liveDataRowsFiltered);
		},
		async clearDataRowsFiltered({ commit }) {
			commit(SET_DATA_ROWS_FILTERED_LIVE, []);
			commit(SET_DATA_ROWS_FILTERED_CURRENT, []);
		},
		async setFilterDatesSelected({ commit }, filterPayload) {
			commit(SET_FILTER_DATES_SELECTED, filterPayload);
		},
		async clearFilterDatesSelected({ commit }) {
			commit(SET_FILTER_DATES_SELECTED, []);
		},
		async setFilterAccountsSelected({ commit }, filterPayload) {
			commit(SET_FILTER_ACCOUNTS_SELECTED, filterPayload);
		},
		async clearFilterAccountsSelected({ commit }) {
			commit(SET_FILTER_ACCOUNTS_SELECTED, []);
		},
		async setIsAnalyticsDataDirty({ commit }, filterPayload) {
			commit(SET_IS_ANALYTICS_DATA_DIRTY, filterPayload);
		},
		/*
		async updateTableFilters({ commit }, filterParams = { name: null, move: null, type: null }, filterPayload = []) {
			const filterKeys = ['name', 'move', 'type'];
			const validFilters = {
				name: ['state', 'area', 'region'],
				move: ['apply', 'revert'],
				type: ['current', 'live']
			};
			const validParams = {
				name: false,
				move: false,
				type: false,
			}
			for (let i = 0; i < filterKeys.length; i++) {
				let useKey = filterKeys[i];
				if (useKey in filterParams) {
					let useValid = validFilters[useKey];
					let rawParam = filterParams[useKey];
					let useParam = '';
					if (typeof rawParam === 'string' || rawParam instanceof String) {
						useParam = String(rawParam).trim().toLowerCase();
						if (useValid.includes(useParam)) {
							validParams[useKey] = true;
						}
					}
				}
			}
			const isValidAction = Object.keys(validParams).every(function (k) { return validParams[k] === true });
			if (isValidAction === true) {
				if (filterParams.name === 'state') {

				} else if (filterParams.name === 'area') {

				} else if (filterParams.name === 'region') {

				}
			}
		},
		*/
		async patchItem({ commit }, item_data) {
			await Items.patch(item_data)
				.then((item) => {
					commit(PATCH_ITEM, item);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async deleteItem({ commit }, item_id) {
			await Items.delete(item_id)
				.then((resp) => {
					commit(DELETE_ITEM, item_id);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async createItem({ commit }, item_data) {
			await Items.create(item_data)
				.then((item) => {
					commit(CREATE_ITEM, item);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		setGoogleDataDealers({ commit }, value) {
			commit(SET_GOOGLE_DATA_DEALERS, value);
		},
		setShowGoogleDataModal({ commit }, value) {
			commit(SET_SHOW_GOOGLE_DATA_MODAL, value);
		},
		setGoogleDataDealerFilter({ commit }, value) {
			commit(SET_GOOGLE_DATA_DEALER_FILTERS, value);
		},
		clearGoogleDataDealerFilter({ commit }) {
			commit(CLEAR_GOOGLE_DATA_DEALER_FILTERS);
		},
	},
	mutations: {
		[SET_ANALYTICS_READY](state, payload) {
			state.analyticsDataReady = payload;
		},
		[SET_DASH_DATA](state, items) {
			const functionLogTitle = consoleLogTitle + 'mutations.[SET_DASH_DATA] - ';
			console.log(functionLogTitle + 'Passed (items):', items);
			const stDashData = state.dashboardData;
			console.log(functionLogTitle + 'Current (state.dashboardData):', stDashData);
			const jsonDash = JSON.parse(JSON.stringify(items));
			let builtDash = {};
			for (const [dashKey, dashData] of Object.entries(jsonDash)) {
				builtDash[dashKey] = dashData;
			}
			console.log(functionLogTitle + 'Final (builtDash):', builtDash);
			state.dashboardData = builtDash;
		},
		[SET_DASH_READY](state, payload) {
			state.dashDataReady = payload;
		},
		[SET_RANK_DATA](state, items) {
			const functionLogTitle = consoleLogTitle + 'mutations.[SET_RANK_DATA] - ';
			console.log(functionLogTitle + 'Passed (items):', items);
			const stRankData = state.rankAnalytics;
			console.log(functionLogTitle + 'Current (state.rankAnalytics):', stRankData);
			const jsonRanks = JSON.parse(JSON.stringify(items));
			let builtRanks = {};
			for (const [rankKey, rankData] of Object.entries(jsonRanks)) {
				builtRanks[rankKey] = rankData;
			}
			console.log(functionLogTitle + 'Final (builtRanks):', builtRanks);
			state.rankAnalytics = builtRanks;
		},
		[SET_RANK_READY](state, payload) {
			state.rankDataReady = payload;
		},
		[SET_KIOSK_DATA](state, items) {
			if (items.kioskDetails) {
				state.kioskData = items.kioskDetails;
			}
		},
		[SET_KIOSK_COUNTS](state, items) {
			if (items.kioskCounts) {
				if (typeof items.kioskCounts === 'object' || items.kioskCounts instanceof Object) {
					state.kioskCounts = items.kioskCounts;
				}
			}
		},
		[SET_KIOSK_MAP_MARKERS](state, items) {
			if (items.mapMarkers) {
				state.kioskMapMarkers = items.mapMarkers;
			}
		},
		[SET_KIOSK_MAP_HEAT_DATA](state, items) {
			if (items.heatMapData) {
				state.kioskMapHeatData = items.heatMapData;
			}
		},
		[SET_KIOSK_MAP_HEAT_OPTIONS](state, items) {
			if (items.heatMapOptions) {
				state.kioskMapHeatOptions = items.heatMapOptions;
			}
		},
		[SET_KIOSK_MAP_INFO_WINDOWS](state, items) {
			if (items.infoWindows) {
				state.kioskMapInfoWindows = items.infoWindows;
			}
		},
		[SET_DEALER_DETAILS](state, items) {
			if (items.dealerDetails) {
				state.dealerDetails = items.dealerDetails;
			}
		},
		[SET_DEALER_TABLE_DATA](state, items) {
			if (items.tableDealerDetails) {
				state.dealerTableData = items.tableDealerDetails;
			}
		},
		[SET_DEALERS_GROUPED_BY_STATE](state, items) {
			if (items.stateGroupedDealers) {
				state.dealersGroupedByState = items.stateGroupedDealers;
			}
		},
		[SET_DEALERS_KEYED_BY_STATE](state, items) {
			if (items.stateKeyedDealers) {
				state.dealersKeyedByState = items.stateKeyedDealers;
			}
		},
		[SET_VW_STATE_LIST](state, items) {
			if (items.vwStateFilterList) {
				state.vwStateList = items.vwStateFilterList;
			}
		},
		[SET_VW_STATE_COUNTS](state, items) {
			if (items.vwStateList) {
				state.vwStateCounts = items.vwStateList;
			}
		},
		[SET_VW_AREA_LIST](state, items) {
			if (items.vwAreaList) {
				if (typeof items.vwAreaList === 'object' || items.vwAreaList instanceof Object) {
					state.vwAreaList = Object.values(items.vwAreaList);
				}
			}
		},
		[SET_VW_AREA_OPTIONS](state, items) {
			if (items.vwAreaOptions) {
				if (typeof items.vwAreaOptions === 'object' || items.vwAreaOptions instanceof Object) {
					state.vwAreaOptions = Object.values(items.vwAreaOptions);
				}
			}
		},
		[SET_VW_REGION_LIST](state, items) {
			if (items.vwRegionList) {
				if (typeof items.vwRegionList === 'object' || items.vwRegionList instanceof Object) {
					state.vwRegionList = Object.values(items.vwRegionList);
				}
			}
		},
		[SET_VW_REGION_OPTIONS](state, items) {
			if (items.vwRegionOptions) {
				if (typeof items.vwRegionOptions === 'object' || items.vwRegionOptions instanceof Object) {
					state.vwRegionOptions = Object.values(items.vwRegionOptions);
				}
			}
		},
		[SET_VW_STATES_SELECTED_CURRENT](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				state.currentStatesSelected = items;
			}
		},
		[SET_VW_STATES_SELECTED_LIVE](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				state.liveStatesSelected = items;
			}
		},
		[SET_VW_AREAS_SELECTED_CURRENT](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				state.currentAreasSelected = items;
			}
		},
		[SET_VW_AREAS_SELECTED_LIVE](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				state.liveAreasSelected = items;
			}
		},
		[SET_VW_REGIONS_SELECTED_CURRENT](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				state.currentRegionsSelected = items;
			}
		},
		[SET_VW_REGIONS_SELECTED_LIVE](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				state.liveRegionsSelected = items;
			}
		},
		[SET_DATA_FILTER_MODEL_CURRENT](state, items) {
			if (typeof items === 'object' || items instanceof Object) {
				state.currentDataFilterModel = JSON.parse(JSON.stringify(items));
			}
		},
		[SET_DATA_FILTER_MODEL_LIVE](state, items) {
			if (typeof items === 'object' || items instanceof Object) {
				state.liveDataFilterModel = JSON.parse(JSON.stringify(items));
			}
		},
		[SET_DATA_ROWS_SELECTED_CURRENT](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				const rowsArray = [];
				for (const workItem of items) {
					rowsArray.push(workItem);
				}
				state.currentDataRowsSelected = rowsArray;
			}
		},
		[SET_DATA_ROWS_SELECTED_LIVE](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				const rowsArray = [];
				for (const workItem of items) {
					rowsArray.push(workItem);
				}
				state.liveDataRowsSelected = rowsArray;
			}
		},
		[SET_DATA_NODES_SELECTED_CURRENT](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				state.currentDataNodesSelected = items;
			}
		},
		[SET_DATA_NODES_SELECTED_LIVE](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				state.liveDataNodesSelected = items;
			}
		},
		[SET_DATA_ROWS_FILTERED_CURRENT](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				const rowsArray = [];
				for (const workItem of items) {
					rowsArray.push(workItem);
				}
				state.currentDataRowsFiltered = rowsArray;
			}
		},
		[SET_DATA_ROWS_FILTERED_LIVE](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				const rowsArray = [];
				for (const workItem of items) {
					rowsArray.push(workItem);
				}
				state.liveDataRowsFiltered = rowsArray;
			}
		},
		[SET_FILTER_DATES_SELECTED](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				const rowsArray = [];
				for (const workItem of items) {
					rowsArray.push(workItem);
				}
				state.filterDatesSelected = rowsArray;
			}
		},
		[SET_FILTER_ACCOUNTS_SELECTED](state, items) {
			if (typeof items === 'array' || items instanceof Array) {
				const rowsArray = [];
				for (const workItem of items) {
					rowsArray.push(workItem);
				}
				state.filterAccountsSelected = rowsArray;
			}
		},
		[SET_IS_ANALYTICS_DATA_DIRTY](state, isDirty) {
			if (isDirty === true || isDirty === false) {
				state.isAnalyticsDataDirty = isDirty;
			}
		},
		[SET_NOW_DASH_TOOLTIPS](state, items) {
			if (items.dashTooltips) {
				state.nowDashTooltips = items.dashTooltips;
			}
		},
		[SET_NOW_DASH_DEFAULT_TOOLTIP](state, items) {
			if (items.dashTooltips) {
				if ('default' in items.dashTooltips) {
					state.nowDashTooltipDefault = items.dashTooltips.default;
				}
			}
		},
		[SET_LOADING](state, payload) {
			state.loading = payload;
		},
		[SET_HEADERS](state, headers) {
			const stateHeaders = state.headers;
			const builtHeaders = {
				...stateHeaders,
				...headers,
			};
			state.headers = builtHeaders;
		},
		[SET_PAGINATION](state, pagination) {
			const statePagination = state.pagination;
			const builtPagination = {
				...statePagination,
				...pagination,
			};
			state.pagination = builtPagination;
		},
		[SET_ITEMS](state, items) {
			state.items = items;
		},
		[PATCH_ITEM](state, items) {
			let id = items.id;
			state.items.filter((items) => {
				return items.id === id;
			})[0] = items;
		},
		[CREATE_ITEM](state, item) {
			state.items = [item, ...state.items];
		},
		[DELETE_ITEM](state, { id }) {
			state.items = state.items.filter((item) => {
				return item.id !== id;
			});
		},
		[SET_SEARCH](state, payload) {
			state.search = payload;
		},
		[SET_GOOGLE_DATA_EXPORT_NAME](state, payload) {
			state.googleDataExportName = payload;
		},
		[SET_GOOGLE_DATA](state, payload) {
			state.googleData = payload;
		},
		[SET_DEALER_PAGE_VIEWS](state, payload) {
			state.dealerPageViews = payload;
		},
		[SET_DEALER_PAGE_VIEWS_COL_DEFS](state, payload) {
			state.agDealerPageViewsColDefs = payload;
		},
		[SET_AG_GOOGLE_DATA_COL_DEFS](state, payload) {
			state.agGoogleDataColDefs = payload;
		},
		[SET_SHOW_GOOGLE_DATA_MODAL](state, payload) {
			state.showGoogleDataModal = payload;
		},
		[SET_GOOGLE_DATA_DEALERS](state, payload) {
			state.googleDataDealers = payload;
		},
		[SET_GOOGLE_DATA_DEALER_FILTERS](state, payload) {
			state.googleDataDealerFilters = payload;
		},
		[CLEAR_GOOGLE_DATA_DEALER_FILTERS](state) {
			state.googleDataDealerFilters = null;
		},
	},
};

export default NowDashState;