import { HTTP } from "./OtherDashStateDataCommon";

function createHTTP(service, url) {
	return {
		async retrieve(dateFiltered = false, queryParams) {
			const functionLogTitle = `[createHTTP][${service}] - async [retrieve]: `;
			console.log(functionLogTitle + 'Begin execution...');
			console.log(functionLogTitle + '(dateFiltered) variable passed: ' + dateFiltered);
			console.log(functionLogTitle + '(queryParams) variable passed:', queryParams);
			let retrieveURL = `${url}`;
			if (dateFiltered === true) {
				console.log(functionLogTitle + '(dateFiltered) === true, building date filtered URL...');
				retrieveURL = `${url}/${queryParams.dateStart}/${queryParams.dateEnd}`;
			}
			console.log(functionLogTitle + 'Firing HTTP.post command to (retrieveURL) variable, awaiting (response)...');
			console.log(functionLogTitle + '(retrieveURL) variable for HTTP.post: ' + retrieveURL);
			return HTTP.post(retrieveURL, queryParams).then(response => {
				console.log(functionLogTitle + '(response) received via HTTP.post command from (retrieveURL): ' + retrieveURL);
				console.log(functionLogTitle + '(response) variable received:', response);
				console.log(functionLogTitle + 'Check for (data) key in received (response) object...');
				if (response.data) {
					console.log(functionLogTitle + '(data) key *IS* present in (response) object, returning (response.data)...');
					return response.data;
				}
				console.log(functionLogTitle + '(data) key *NOT* present in (response) object, returning (response)...');
				return response;
			})
		},
	}
}

export const AnalyticsDashStateDataService = createHTTP(`AnalyticsDashStateDataService`, `/analytics`);
export const TelemetryDashStateDataService = createHTTP(`TelemetryDashStateDataService`, `/telemetry`);
export const ConfigureDashStateDataService = createHTTP(`ConfigureDashStateDataService`, `/configure`);
