/**
 * Analytics Mutations for Analytics Summary Dashboard
 */
export const SET_ANALYTICS_READY = 'SET_ANALYTICS_READY';
export const SET_ANALYTICS_DATA = 'SET_ANALYTICS_DATA';
export const SET_ANALYTICS_DATA_READY = 'SET_ANALYTICS_DATA_READY';

export const SET_ANALYTICS_LOADING = 'SET_ANALYTICS_LOADING';
export const SET_IS_ANALYTICS_DATA_DIRTY = 'SET_IS_ANALYTICS_DATA_DIRTY';
/**
 * End Analytics Mutations
 */

/**
 * Telemetry Mutations for Telemetry Summary Dashboard
 */
export const SET_TELEMETRY_UPDATE_TIME = 'SET_TELEMETRY_UPDATE_TIME';
export const SET_TELEMETRY_READY = 'SET_TELEMETRY_READY';
export const SET_TELEMETRY_DATA = 'SET_TELEMETRY_DATA';
export const SET_TELEMETRY_DATA_READY = 'SET_TELEMETRY_DATA_READY';

export const SET_TELE_DEFAULTS_DATA = 'SET_TELE_DEFAULTS_DATA';
export const SET_TELE_CUSTOMER_DATA = 'SET_TELE_CUSTOMER_DATA';
export const SET_TELE_DETAILED_DATA = 'SET_TELE_DETAILED_DATA';
export const SET_TELE_MAP_DEFAULT_MARKERS = 'SET_TELE_MAP_DEFAULT_MARKERS';
export const SET_TELE_MAP_UPDATED_MARKERS = 'SET_TELE_MAP_UPDATED_MARKERS';
export const SET_TELE_MAP_INFO_WINDOWS = 'SET_TELE_MAP_INFO_WINDOWS';

export const SET_TELEMETRY_LOADING = 'SET_TELEMETRY_LOADING';
export const SET_IS_TELEMETRY_DATA_DIRTY = 'SET_IS_TELEMETRY_DATA_DIRTY';
/**
 * End Telemetry Mutations
 */

/**
 * Configure Mutations for Configure Summary Dashboard
 */
export const SET_CONFIGURE_UPDATE_TIME = 'SET_CONFIGURE_UPDATE_TIME';
export const SET_CONFIGURE_READY = 'SET_CONFIGURE_READY';
export const SET_CONFIGURE_DATA = 'SET_CONFIGURE_DATA';
export const SET_CONFIGURE_DATA_READY = 'SET_CONFIGURE_DATA_READY';

export const SET_CONFIGURE_LOADING = 'SET_CONFIGURE_LOADING';
export const SET_IS_CONFIGURE_DATA_DIRTY = 'SET_IS_CONFIGURE_DATA_DIRTY';
/**
 * End Configure Mutations
 */

export const SET_KIOSK_DATA = 'SET_KIOSK_DATA';
export const SET_KIOSK_COUNTS = 'SET_KIOSK_COUNTS';

export const SET_KIOSK_MAP_MARKERS = 'SET_KIOSK_MAP_MARKERS';

export const SET_KIOSK_MAP_HEAT_DATA = 'SET_KIOSK_MAP_HEAT_DATA';
export const SET_KIOSK_MAP_HEAT_OPTIONS = 'SET_KIOSK_MAP_HEAT_OPTIONS';

export const SET_KIOSK_MAP_INFO_WINDOWS = 'SET_KIOSK_MAP_INFO_WINDOWS';

export const SET_DEALER_DETAILS = 'SET_DEALER_DETAILS';

export const SET_DEALER_TABLE_DATA = 'SET_DEALER_TABLE_DATA';

export const SET_DEALERS_GROUPED_BY_STATE = 'SET_DEALERS_GROUPED_BY_STATE';

export const SET_DEALERS_KEYED_BY_STATE = 'SET_DEALERS_KEYED_BY_STATE';

export const SET_VW_STATE_LIST = 'SET_VW_STATE_LIST';
export const SET_VW_STATE_COUNTS = 'SET_VW_STATE_COUNTS';

export const SET_VW_AREA_LIST = 'SET_VW_AREA_LIST';
export const SET_VW_AREA_OPTIONS = 'SET_VW_AREA_OPTIONS';

export const SET_VW_REGION_LIST = 'SET_VW_REGION_LIST';
export const SET_VW_REGION_OPTIONS = 'SET_VW_REGION_OPTIONS';

export const SET_VW_STATES_SELECTED_CURRENT = 'SET_VW_STATES_SELECTED_CURRENT';
export const SET_VW_STATES_SELECTED_LIVE = 'SET_VW_STATES_SELECTED_LIVE';

export const SET_VW_AREAS_SELECTED_CURRENT = 'SET_VW_AREAS_SELECTED_CURRENT';
export const SET_VW_AREAS_SELECTED_LIVE = 'SET_VW_AREAS_SELECTED_LIVE';

export const SET_VW_REGIONS_SELECTED_CURRENT = 'SET_VW_REGIONS_SELECTED_CURRENT';
export const SET_VW_REGIONS_SELECTED_LIVE = 'SET_VW_REGIONS_SELECTED_LIVE';

export const SET_DATA_FILTER_MODEL_CURRENT = 'SET_DATA_FILTER_MODEL_CURRENT';
export const SET_DATA_FILTER_MODEL_LIVE = 'SET_DATA_FILTER_MODEL_LIVE';

export const SET_DATA_ROWS_SELECTED_CURRENT = 'SET_DATA_ROWS_SELECTED_CURRENT';
export const SET_DATA_ROWS_SELECTED_LIVE = 'SET_DATA_ROWS_SELECTED_LIVE';

export const SET_DATA_NODES_SELECTED_CURRENT = 'SET_DATA_NODES_SELECTED_CURRENT';
export const SET_DATA_NODES_SELECTED_LIVE = 'SET_DATA_NODES_SELECTED_LIVE';

export const SET_DATA_ROWS_FILTERED_CURRENT = 'SET_DATA_ROWS_FILTERED_CURRENT';
export const SET_DATA_ROWS_FILTERED_LIVE = 'SET_DATA_ROWS_FILTERED_LIVE';

export const SET_FILTER_DATES_SELECTED = 'SET_FILTER_DATES_SELECTED';
export const SET_FILTER_ACCOUNTS_SELECTED = 'SET_FILTER_ACCOUNTS_SELECTED';

