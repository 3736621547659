export const cmpPackagesTree = [
	{
		"id": 0,
		"label": "bacon",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "bacon-qr-code",
				"type": "child",
				"package": "bacon/bacon-qr-code@2.0.8",
				"version": "2.0.8",
				"require": {
					"key": "bacon/bacon-qr-code",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "bacon"
			}
		]
	},
	{
		"id": 1,
		"label": "barryvdh",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "laravel-ide-helper",
				"type": "child",
				"package": "barryvdh/laravel-ide-helper@2.13.0",
				"version": "2.13.0",
				"require": {
					"key": "barryvdh/laravel-ide-helper",
					"ver": "^2.13",
					"type": "gen",
					"desc": "App"
				},
				"parent": "barryvdh"
			},
			{
				"id": 1,
				"label": "reflection-docblock",
				"type": "child",
				"package": "barryvdh/reflection-docblock@2.1.1",
				"version": "2.1.1",
				"require": {
					"key": "barryvdh/reflection-docblock",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "barryvdh"
			}
		]
	},
	{
		"id": 2,
		"label": "beyondcode",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "laravel-websockets",
				"type": "child",
				"package": "beyondcode/laravel-websockets@1.14.0",
				"version": "1.14.0",
				"require": {
					"key": "beyondcode/laravel-websockets",
					"ver": "^1.14",
					"type": "gen",
					"desc": "App"
				},
				"parent": "beyondcode"
			}
		]
	},
	{
		"id": 3,
		"label": "brick",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "math",
				"type": "child",
				"package": "brick/math@0.11.0",
				"version": "0.11.0",
				"require": {
					"key": "brick/math",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "brick"
			}
		]
	},
	{
		"id": 4,
		"label": "cboden",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "ratchet",
				"type": "child",
				"package": "cboden/ratchet@0.4.4",
				"version": "0.4.4",
				"require": {
					"key": "cboden/ratchet",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "cboden"
			}
		]
	},
	{
		"id": 5,
		"label": "composer",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "class-map-generator",
				"type": "child",
				"package": "composer/class-map-generator@1.1.0",
				"version": "1.1.0",
				"require": {
					"key": "composer/class-map-generator",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "composer"
			},
			{
				"id": 1,
				"label": "pcre",
				"type": "child",
				"package": "composer/pcre@3.1.0",
				"version": "3.1.0",
				"require": {
					"key": "composer/pcre",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "composer"
			}
		]
	},
	{
		"id": 6,
		"label": "dasprid",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "enum",
				"type": "child",
				"package": "dasprid/enum@1.0.4",
				"version": "1.0.4",
				"require": {
					"key": "dasprid/enum",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "dasprid"
			}
		]
	},
	{
		"id": 7,
		"label": "dflydev",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "dot-access-data",
				"type": "child",
				"package": "dflydev/dot-access-data@3.0.2",
				"version": "3.0.2",
				"require": {
					"key": "dflydev/dot-access-data",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "dflydev"
			}
		]
	},
	{
		"id": 8,
		"label": "doctrine",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "cache",
				"type": "child",
				"package": "doctrine/cache@2.2.0",
				"version": "2.2.0",
				"require": {
					"key": "doctrine/cache",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "doctrine"
			},
			{
				"id": 1,
				"label": "dbal",
				"type": "child",
				"package": "doctrine/dbal@3.6.6",
				"version": "3.6.6",
				"require": {
					"key": "doctrine/dbal",
					"ver": "^3.6",
					"type": "gen",
					"desc": "App"
				},
				"parent": "doctrine"
			},
			{
				"id": 2,
				"label": "deprecations",
				"type": "child",
				"package": "doctrine/deprecations@1.1.1",
				"version": "1.1.1",
				"require": {
					"key": "doctrine/deprecations",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "doctrine"
			},
			{
				"id": 3,
				"label": "event-manager",
				"type": "child",
				"package": "doctrine/event-manager@2.0.0",
				"version": "2.0.0",
				"require": {
					"key": "doctrine/event-manager",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "doctrine"
			},
			{
				"id": 4,
				"label": "inflector",
				"type": "child",
				"package": "doctrine/inflector@2.0.8",
				"version": "2.0.8",
				"require": {
					"key": "doctrine/inflector",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "doctrine"
			},
			{
				"id": 5,
				"label": "lexer",
				"type": "child",
				"package": "doctrine/lexer@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "doctrine/lexer",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "doctrine"
			}
		]
	},
	{
		"id": 9,
		"label": "dragonmantank",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "cron-expression",
				"type": "child",
				"package": "dragonmantank/cron-expression@3.3.3",
				"version": "3.3.3",
				"require": {
					"key": "dragonmantank/cron-expression",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "dragonmantank"
			}
		]
	},
	{
		"id": 10,
		"label": "egulias",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "email-validator",
				"type": "child",
				"package": "egulias/email-validator@4.0.1",
				"version": "4.0.1",
				"require": {
					"key": "egulias/email-validator",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "egulias"
			}
		]
	},
	{
		"id": 11,
		"label": "evenement",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "evenement",
				"type": "child",
				"package": "evenement/evenement@3.0.2",
				"version": "3.0.2",
				"require": {
					"key": "evenement/evenement",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "evenement"
			}
		]
	},
	{
		"id": 12,
		"label": "facade",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "ignition-contracts",
				"type": "child",
				"package": "facade/ignition-contracts@1.0.2",
				"version": "1.0.2",
				"require": {
					"key": "facade/ignition-contracts",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "facade"
			}
		]
	},
	{
		"id": 13,
		"label": "fakerphp",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "faker",
				"type": "child",
				"package": "fakerphp/faker@1.23.0",
				"version": "1.23.0",
				"require": {
					"key": "fakerphp/faker",
					"ver": "^1.9.1",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "fakerphp"
			}
		]
	},
	{
		"id": 14,
		"label": "fig",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "http-message-util",
				"type": "child",
				"package": "fig/http-message-util@1.1.5",
				"version": "1.1.5",
				"require": {
					"key": "fig/http-message-util",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "fig"
			}
		]
	},
	{
		"id": 15,
		"label": "filp",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "whoops",
				"type": "child",
				"package": "filp/whoops@2.15.3",
				"version": "2.15.3",
				"require": {
					"key": "filp/whoops",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "filp"
			}
		]
	},
	{
		"id": 16,
		"label": "firebase",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "php-jwt",
				"type": "child",
				"package": "firebase/php-jwt@6.8.1",
				"version": "6.8.1",
				"require": {
					"key": "firebase/php-jwt",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "firebase"
			}
		]
	},
	{
		"id": 17,
		"label": "fruitcake",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "php-cors",
				"type": "child",
				"package": "fruitcake/php-cors@1.2.0",
				"version": "1.2.0",
				"require": {
					"key": "fruitcake/php-cors",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "fruitcake"
			}
		]
	},
	{
		"id": 18,
		"label": "google",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "analytics-data",
				"type": "child",
				"package": "google/analytics-data@0.9.5",
				"version": "0.9.5",
				"require": {
					"key": "google/analytics-data",
					"ver": "^0.9.2",
					"type": "gen",
					"desc": "App"
				},
				"parent": "google"
			},
			{
				"id": 1,
				"label": "auth",
				"type": "child",
				"package": "google/auth@1.29.1",
				"version": "1.29.1",
				"require": {
					"key": "google/auth",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "google"
			},
			{
				"id": 2,
				"label": "common-protos",
				"type": "child",
				"package": "google/common-protos@4.3.0",
				"version": "4.3.0",
				"require": {
					"key": "google/common-protos",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "google"
			},
			{
				"id": 3,
				"label": "gax",
				"type": "child",
				"package": "google/gax@1.22.1",
				"version": "1.22.1",
				"require": {
					"key": "google/gax",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "google"
			},
			{
				"id": 4,
				"label": "grpc-gcp",
				"type": "child",
				"package": "google/grpc-gcp@0.3.0",
				"version": "0.3.0",
				"require": {
					"key": "google/grpc-gcp",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "google"
			},
			{
				"id": 5,
				"label": "longrunning",
				"type": "child",
				"package": "google/longrunning@0.2.6",
				"version": "0.2.6",
				"require": {
					"key": "google/longrunning",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "google"
			},
			{
				"id": 6,
				"label": "protobuf",
				"type": "child",
				"package": "google/protobuf@3.24.1",
				"version": "3.24.1",
				"require": {
					"key": "google/protobuf",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "google"
			}
		]
	},
	{
		"id": 19,
		"label": "graham-campbell",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "guzzle-factory",
				"type": "child",
				"package": "graham-campbell/guzzle-factory@5.1.2",
				"version": "5.1.2",
				"require": {
					"key": "graham-campbell/guzzle-factory",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "graham-campbell"
			},
			{
				"id": 1,
				"label": "result-type",
				"type": "child",
				"package": "graham-campbell/result-type@1.1.1",
				"version": "1.1.1",
				"require": {
					"key": "graham-campbell/result-type",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "graham-campbell"
			}
		]
	},
	{
		"id": 20,
		"label": "grpc",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "grpc",
				"type": "child",
				"package": "grpc/grpc@1.57.0",
				"version": "1.57.0",
				"require": {
					"key": "grpc/grpc",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "grpc"
			}
		]
	},
	{
		"id": 21,
		"label": "guzzlehttp",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "guzzle",
				"type": "child",
				"package": "guzzlehttp/guzzle@7.7.0",
				"version": "7.7.0",
				"require": {
					"key": "guzzlehttp/guzzle",
					"ver": "^7.2",
					"type": "gen",
					"desc": "App"
				},
				"parent": "guzzlehttp"
			},
			{
				"id": 1,
				"label": "promises",
				"type": "child",
				"package": "guzzlehttp/promises@2.0.1",
				"version": "2.0.1",
				"require": {
					"key": "guzzlehttp/promises",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "guzzlehttp"
			},
			{
				"id": 2,
				"label": "psr7",
				"type": "child",
				"package": "guzzlehttp/psr7@2.6.0",
				"version": "2.6.0",
				"require": {
					"key": "guzzlehttp/psr7",
					"ver": "^2.4",
					"type": "gen",
					"desc": "App"
				},
				"parent": "guzzlehttp"
			},
			{
				"id": 3,
				"label": "uri-template",
				"type": "child",
				"package": "guzzlehttp/uri-template@1.0.1",
				"version": "1.0.1",
				"require": {
					"key": "guzzlehttp/uri-template",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "guzzlehttp"
			}
		]
	},
	{
		"id": 22,
		"label": "hamcrest",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "hamcrest-php",
				"type": "child",
				"package": "hamcrest/hamcrest-php@2.0.1",
				"version": "2.0.1",
				"require": {
					"key": "hamcrest/hamcrest-php",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "hamcrest"
			}
		]
	},
	{
		"id": 23,
		"label": "haruncpi",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "laravel-user-activity",
				"type": "child",
				"package": "haruncpi/laravel-user-activity@dev-l10-php8-human",
				"version": "dev-l10-php8-human",
				"require": {
					"key": "haruncpi/laravel-user-activity",
					"ver": "dev-l10-php8-human",
					"type": "gen",
					"desc": "App"
				},
				"parent": "haruncpi"
			}
		]
	},
	{
		"id": 24,
		"label": "inertiajs",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "inertia-laravel",
				"type": "child",
				"package": "inertiajs/inertia-laravel@0.6.9",
				"version": "0.6.9",
				"require": {
					"key": "inertiajs/inertia-laravel",
					"ver": "^0.6.3",
					"type": "gen",
					"desc": "App"
				},
				"parent": "inertiajs"
			}
		]
	},
	{
		"id": 25,
		"label": "jaybizzle",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "crawler-detect",
				"type": "child",
				"package": "jaybizzle/crawler-detect@1.2.116",
				"version": "1.2.116",
				"require": {
					"key": "jaybizzle/crawler-detect",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "jaybizzle"
			}
		]
	},
	{
		"id": 26,
		"label": "jenssegers",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "agent",
				"type": "child",
				"package": "jenssegers/agent@2.6.4",
				"version": "2.6.4",
				"require": {
					"key": "jenssegers/agent",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "jenssegers"
			}
		]
	},
	{
		"id": 27,
		"label": "laravel",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "fortify",
				"type": "child",
				"package": "laravel/fortify@1.17.5",
				"version": "1.17.5",
				"require": {
					"key": "laravel/fortify",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "laravel"
			},
			{
				"id": 1,
				"label": "framework",
				"type": "child",
				"package": "laravel/framework@10.20.0",
				"version": "10.20.0",
				"require": {
					"key": "laravel/framework",
					"ver": "^10.0",
					"type": "gen",
					"desc": "App"
				},
				"parent": "laravel"
			},
			{
				"id": 2,
				"label": "horizon",
				"type": "child",
				"package": "laravel/horizon@5.19.1",
				"version": "5.19.1",
				"require": {
					"key": "laravel/horizon",
					"ver": "^5.10",
					"type": "gen",
					"desc": "App"
				},
				"parent": "laravel"
			},
			{
				"id": 3,
				"label": "jetstream",
				"type": "child",
				"package": "laravel/jetstream@2.16.2",
				"version": "2.16.2",
				"require": {
					"key": "laravel/jetstream",
					"ver": "^2.12",
					"type": "gen",
					"desc": "App"
				},
				"parent": "laravel"
			},
			{
				"id": 4,
				"label": "pint",
				"type": "child",
				"package": "laravel/pint@1.11.0",
				"version": "1.11.0",
				"require": {
					"key": "laravel/pint",
					"ver": "^1.0",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "laravel"
			},
			{
				"id": 5,
				"label": "prompts",
				"type": "child",
				"package": "laravel/prompts@0.1.6",
				"version": "0.1.6",
				"require": {
					"key": "laravel/prompts",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "laravel"
			},
			{
				"id": 6,
				"label": "sail",
				"type": "child",
				"package": "laravel/sail@1.23.4",
				"version": "1.23.4",
				"require": {
					"key": "laravel/sail",
					"ver": "^1.0.1",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "laravel"
			},
			{
				"id": 7,
				"label": "sanctum",
				"type": "child",
				"package": "laravel/sanctum@3.2.6",
				"version": "3.2.6",
				"require": {
					"key": "laravel/sanctum",
					"ver": "^3.2",
					"type": "gen",
					"desc": "App"
				},
				"parent": "laravel"
			},
			{
				"id": 8,
				"label": "serializable-closure",
				"type": "child",
				"package": "laravel/serializable-closure@1.3.1",
				"version": "1.3.1",
				"require": {
					"key": "laravel/serializable-closure",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "laravel"
			},
			{
				"id": 9,
				"label": "slack-notification-channel",
				"type": "child",
				"package": "laravel/slack-notification-channel@2.5.0",
				"version": "2.5.0",
				"require": {
					"key": "laravel/slack-notification-channel",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "laravel"
			},
			{
				"id": 10,
				"label": "telescope",
				"type": "child",
				"package": "laravel/telescope@4.16.1",
				"version": "4.16.1",
				"require": {
					"key": "laravel/telescope",
					"ver": "^4.13",
					"type": "gen",
					"desc": "App"
				},
				"parent": "laravel"
			},
			{
				"id": 11,
				"label": "tinker",
				"type": "child",
				"package": "laravel/tinker@2.8.1",
				"version": "2.8.1",
				"require": {
					"key": "laravel/tinker",
					"ver": "^2.7",
					"type": "gen",
					"desc": "App"
				},
				"parent": "laravel"
			}
		]
	},
	{
		"id": 28,
		"label": "league",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "commonmark",
				"type": "child",
				"package": "league/commonmark@2.4.0",
				"version": "2.4.0",
				"require": {
					"key": "league/commonmark",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "league"
			},
			{
				"id": 1,
				"label": "config",
				"type": "child",
				"package": "league/config@1.2.0",
				"version": "1.2.0",
				"require": {
					"key": "league/config",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "league"
			},
			{
				"id": 2,
				"label": "flysystem",
				"type": "child",
				"package": "league/flysystem@3.15.1",
				"version": "3.15.1",
				"require": {
					"key": "league/flysystem",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "league"
			},
			{
				"id": 3,
				"label": "flysystem-local",
				"type": "child",
				"package": "league/flysystem-local@3.15.0",
				"version": "3.15.0",
				"require": {
					"key": "league/flysystem-local",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "league"
			},
			{
				"id": 4,
				"label": "mime-type-detection",
				"type": "child",
				"package": "league/mime-type-detection@1.13.0",
				"version": "1.13.0",
				"require": {
					"key": "league/mime-type-detection",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "league"
			}
		]
	},
	{
		"id": 29,
		"label": "lorisleiva",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "cron-translator",
				"type": "child",
				"package": "lorisleiva/cron-translator@0.4.4",
				"version": "0.4.4",
				"require": {
					"key": "lorisleiva/cron-translator",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "lorisleiva"
			}
		]
	},
	{
		"id": 30,
		"label": "masterminds",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "html5",
				"type": "child",
				"package": "masterminds/html5@2.8.1",
				"version": "2.8.1",
				"require": {
					"key": "masterminds/html5",
					"ver": "^2.7",
					"type": "gen",
					"desc": "App"
				},
				"parent": "masterminds"
			}
		]
	},
	{
		"id": 31,
		"label": "mobiledetect",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "mobiledetectlib",
				"type": "child",
				"package": "mobiledetect/mobiledetectlib@2.8.41",
				"version": "2.8.41",
				"require": {
					"key": "mobiledetect/mobiledetectlib",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "mobiledetect"
			}
		]
	},
	{
		"id": 32,
		"label": "mockery",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "mockery",
				"type": "child",
				"package": "mockery/mockery@1.6.6",
				"version": "1.6.6",
				"require": {
					"key": "mockery/mockery",
					"ver": "^1.4.4",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "mockery"
			}
		]
	},
	{
		"id": 33,
		"label": "monolog",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "monolog",
				"type": "child",
				"package": "monolog/monolog@3.4.0",
				"version": "3.4.0",
				"require": {
					"key": "monolog/monolog",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "monolog"
			}
		]
	},
	{
		"id": 34,
		"label": "myclabs",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "deep-copy",
				"type": "child",
				"package": "myclabs/deep-copy@1.11.1",
				"version": "1.11.1",
				"require": {
					"key": "myclabs/deep-copy",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "myclabs"
			}
		]
	},
	{
		"id": 35,
		"label": "nesbot",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "carbon",
				"type": "child",
				"package": "nesbot/carbon@2.69.0",
				"version": "2.69.0",
				"require": {
					"key": "nesbot/carbon",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "nesbot"
			}
		]
	},
	{
		"id": 36,
		"label": "nette",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "schema",
				"type": "child",
				"package": "nette/schema@1.2.4",
				"version": "1.2.4",
				"require": {
					"key": "nette/schema",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "nette"
			},
			{
				"id": 1,
				"label": "utils",
				"type": "child",
				"package": "nette/utils@4.0.1",
				"version": "4.0.1",
				"require": {
					"key": "nette/utils",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "nette"
			}
		]
	},
	{
		"id": 37,
		"label": "nikic",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "php-parser",
				"type": "child",
				"package": "nikic/php-parser@4.17.1",
				"version": "4.17.1",
				"require": {
					"key": "nikic/php-parser",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "nikic"
			}
		]
	},
	{
		"id": 38,
		"label": "nunomaduro",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "collision",
				"type": "child",
				"package": "nunomaduro/collision@7.8.1",
				"version": "7.8.1",
				"require": {
					"key": "nunomaduro/collision",
					"ver": "^7.0",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "nunomaduro"
			},
			{
				"id": 1,
				"label": "termwind",
				"type": "child",
				"package": "nunomaduro/termwind@1.15.1",
				"version": "1.15.1",
				"require": {
					"key": "nunomaduro/termwind",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "nunomaduro"
			}
		]
	},
	{
		"id": 39,
		"label": "opcodesio",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "log-viewer",
				"type": "child",
				"package": "opcodesio/log-viewer@2.5.5",
				"version": "2.5.5",
				"require": {
					"key": "opcodesio/log-viewer",
					"ver": "^2.0",
					"type": "gen",
					"desc": "App"
				},
				"parent": "opcodesio"
			}
		]
	},
	{
		"id": 40,
		"label": "paragonie",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "constant_time_encoding",
				"type": "child",
				"package": "paragonie/constant_time_encoding@2.6.3",
				"version": "2.6.3",
				"require": {
					"key": "paragonie/constant_time_encoding",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "paragonie"
			},
			{
				"id": 1,
				"label": "random_compat",
				"type": "child",
				"package": "paragonie/random_compat@9.99.100",
				"version": "9.99.100",
				"require": {
					"key": "paragonie/random_compat",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "paragonie"
			},
			{
				"id": 2,
				"label": "sodium_compat",
				"type": "child",
				"package": "paragonie/sodium_compat@1.20.0",
				"version": "1.20.0",
				"require": {
					"key": "paragonie/sodium_compat",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "paragonie"
			}
		]
	},
	{
		"id": 41,
		"label": "phar-io",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "manifest",
				"type": "child",
				"package": "phar-io/manifest@2.0.3",
				"version": "2.0.3",
				"require": {
					"key": "phar-io/manifest",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phar-io"
			},
			{
				"id": 1,
				"label": "version",
				"type": "child",
				"package": "phar-io/version@3.2.1",
				"version": "3.2.1",
				"require": {
					"key": "phar-io/version",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phar-io"
			}
		]
	},
	{
		"id": 42,
		"label": "phpdocumentor",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "reflection-common",
				"type": "child",
				"package": "phpdocumentor/reflection-common@2.2.0",
				"version": "2.2.0",
				"require": {
					"key": "phpdocumentor/reflection-common",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpdocumentor"
			},
			{
				"id": 1,
				"label": "type-resolver",
				"type": "child",
				"package": "phpdocumentor/type-resolver@1.7.3",
				"version": "1.7.3",
				"require": {
					"key": "phpdocumentor/type-resolver",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpdocumentor"
			}
		]
	},
	{
		"id": 43,
		"label": "phpoption",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "phpoption",
				"type": "child",
				"package": "phpoption/phpoption@1.9.1",
				"version": "1.9.1",
				"require": {
					"key": "phpoption/phpoption",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpoption"
			}
		]
	},
	{
		"id": 44,
		"label": "phpstan",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "phpdoc-parser",
				"type": "child",
				"package": "phpstan/phpdoc-parser@1.23.1",
				"version": "1.23.1",
				"require": {
					"key": "phpstan/phpdoc-parser",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpstan"
			}
		]
	},
	{
		"id": 45,
		"label": "phpunit",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "php-code-coverage",
				"type": "child",
				"package": "phpunit/php-code-coverage@10.1.3",
				"version": "10.1.3",
				"require": {
					"key": "phpunit/php-code-coverage",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpunit"
			},
			{
				"id": 1,
				"label": "php-file-iterator",
				"type": "child",
				"package": "phpunit/php-file-iterator@4.0.2",
				"version": "4.0.2",
				"require": {
					"key": "phpunit/php-file-iterator",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpunit"
			},
			{
				"id": 2,
				"label": "php-invoker",
				"type": "child",
				"package": "phpunit/php-invoker@4.0.0",
				"version": "4.0.0",
				"require": {
					"key": "phpunit/php-invoker",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpunit"
			},
			{
				"id": 3,
				"label": "php-text-template",
				"type": "child",
				"package": "phpunit/php-text-template@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "phpunit/php-text-template",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpunit"
			},
			{
				"id": 4,
				"label": "php-timer",
				"type": "child",
				"package": "phpunit/php-timer@6.0.0",
				"version": "6.0.0",
				"require": {
					"key": "phpunit/php-timer",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "phpunit"
			},
			{
				"id": 5,
				"label": "phpunit",
				"type": "child",
				"package": "phpunit/phpunit@10.3.2",
				"version": "10.3.2",
				"require": {
					"key": "phpunit/phpunit",
					"ver": "^10.0",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "phpunit"
			}
		]
	},
	{
		"id": 46,
		"label": "pragmarx",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "google2fa",
				"type": "child",
				"package": "pragmarx/google2fa@8.0.1",
				"version": "8.0.1",
				"require": {
					"key": "pragmarx/google2fa",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "pragmarx"
			}
		]
	},
	{
		"id": 47,
		"label": "psr",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "cache",
				"type": "child",
				"package": "psr/cache@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "psr/cache",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			},
			{
				"id": 1,
				"label": "clock",
				"type": "child",
				"package": "psr/clock@1.0.0",
				"version": "1.0.0",
				"require": {
					"key": "psr/clock",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			},
			{
				"id": 2,
				"label": "container",
				"type": "child",
				"package": "psr/container@2.0.2",
				"version": "2.0.2",
				"require": {
					"key": "psr/container",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			},
			{
				"id": 3,
				"label": "event-dispatcher",
				"type": "child",
				"package": "psr/event-dispatcher@1.0.0",
				"version": "1.0.0",
				"require": {
					"key": "psr/event-dispatcher",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			},
			{
				"id": 4,
				"label": "http-client",
				"type": "child",
				"package": "psr/http-client@1.0.2",
				"version": "1.0.2",
				"require": {
					"key": "psr/http-client",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			},
			{
				"id": 5,
				"label": "http-factory",
				"type": "child",
				"package": "psr/http-factory@1.0.2",
				"version": "1.0.2",
				"require": {
					"key": "psr/http-factory",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			},
			{
				"id": 6,
				"label": "http-message",
				"type": "child",
				"package": "psr/http-message@1.1",
				"version": "1.1",
				"require": {
					"key": "psr/http-message",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			},
			{
				"id": 7,
				"label": "log",
				"type": "child",
				"package": "psr/log@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "psr/log",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			},
			{
				"id": 8,
				"label": "simple-cache",
				"type": "child",
				"package": "psr/simple-cache@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "psr/simple-cache",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psr"
			}
		]
	},
	{
		"id": 48,
		"label": "psy",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "psysh",
				"type": "child",
				"package": "psy/psysh@0.11.20",
				"version": "0.11.20",
				"require": {
					"key": "psy/psysh",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "psy"
			}
		]
	},
	{
		"id": 49,
		"label": "pusher",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "pusher-php-server",
				"type": "child",
				"package": "pusher/pusher-php-server@7.2.3",
				"version": "7.2.3",
				"require": {
					"key": "pusher/pusher-php-server",
					"ver": "^7.2",
					"type": "gen",
					"desc": "App"
				},
				"parent": "pusher"
			}
		]
	},
	{
		"id": 50,
		"label": "raiym",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "instagram-php-scraper",
				"type": "child",
				"package": "raiym/instagram-php-scraper@dev-main",
				"version": "dev-main",
				"require": {
					"key": "raiym/instagram-php-scraper",
					"ver": "dev-main",
					"type": "gen",
					"desc": "App"
				},
				"parent": "raiym"
			}
		]
	},
	{
		"id": 51,
		"label": "ralouphie",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "getallheaders",
				"type": "child",
				"package": "ralouphie/getallheaders@3.0.3",
				"version": "3.0.3",
				"require": {
					"key": "ralouphie/getallheaders",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "ralouphie"
			}
		]
	},
	{
		"id": 52,
		"label": "ramsey",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "collection",
				"type": "child",
				"package": "ramsey/collection@2.0.0",
				"version": "2.0.0",
				"require": {
					"key": "ramsey/collection",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "ramsey"
			},
			{
				"id": 1,
				"label": "uuid",
				"type": "child",
				"package": "ramsey/uuid@4.7.4",
				"version": "4.7.4",
				"require": {
					"key": "ramsey/uuid",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "ramsey"
			}
		]
	},
	{
		"id": 53,
		"label": "ratchet",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "rfc6455",
				"type": "child",
				"package": "ratchet/rfc6455@0.3.1",
				"version": "0.3.1",
				"require": {
					"key": "ratchet/rfc6455",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "ratchet"
			}
		]
	},
	{
		"id": 54,
		"label": "react",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "cache",
				"type": "child",
				"package": "react/cache@1.2.0",
				"version": "1.2.0",
				"require": {
					"key": "react/cache",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "react"
			},
			{
				"id": 1,
				"label": "dns",
				"type": "child",
				"package": "react/dns@1.11.0",
				"version": "1.11.0",
				"require": {
					"key": "react/dns",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "react"
			},
			{
				"id": 2,
				"label": "event-loop",
				"type": "child",
				"package": "react/event-loop@1.4.0",
				"version": "1.4.0",
				"require": {
					"key": "react/event-loop",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "react"
			},
			{
				"id": 3,
				"label": "http",
				"type": "child",
				"package": "react/http@1.9.0",
				"version": "1.9.0",
				"require": {
					"key": "react/http",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "react"
			},
			{
				"id": 4,
				"label": "promise",
				"type": "child",
				"package": "react/promise@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "react/promise",
					"ver": "^3.0",
					"type": "gen",
					"desc": "App"
				},
				"parent": "react"
			},
			{
				"id": 5,
				"label": "socket",
				"type": "child",
				"package": "react/socket@1.14.0",
				"version": "1.14.0",
				"require": {
					"key": "react/socket",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "react"
			},
			{
				"id": 6,
				"label": "stream",
				"type": "child",
				"package": "react/stream@1.3.0",
				"version": "1.3.0",
				"require": {
					"key": "react/stream",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "react"
			}
		]
	},
	{
		"id": 55,
		"label": "ringcentral",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "psr7",
				"type": "child",
				"package": "ringcentral/psr7@1.3.0",
				"version": "1.3.0",
				"require": {
					"key": "ringcentral/psr7",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "ringcentral"
			}
		]
	},
	{
		"id": 56,
		"label": "sebastian",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "cli-parser",
				"type": "child",
				"package": "sebastian/cli-parser@2.0.0",
				"version": "2.0.0",
				"require": {
					"key": "sebastian/cli-parser",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 1,
				"label": "code-unit",
				"type": "child",
				"package": "sebastian/code-unit@2.0.0",
				"version": "2.0.0",
				"require": {
					"key": "sebastian/code-unit",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 2,
				"label": "code-unit-reverse-lookup",
				"type": "child",
				"package": "sebastian/code-unit-reverse-lookup@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "sebastian/code-unit-reverse-lookup",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 3,
				"label": "comparator",
				"type": "child",
				"package": "sebastian/comparator@5.0.1",
				"version": "5.0.1",
				"require": {
					"key": "sebastian/comparator",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 4,
				"label": "complexity",
				"type": "child",
				"package": "sebastian/complexity@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "sebastian/complexity",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 5,
				"label": "diff",
				"type": "child",
				"package": "sebastian/diff@5.0.3",
				"version": "5.0.3",
				"require": {
					"key": "sebastian/diff",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 6,
				"label": "environment",
				"type": "child",
				"package": "sebastian/environment@6.0.1",
				"version": "6.0.1",
				"require": {
					"key": "sebastian/environment",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 7,
				"label": "exporter",
				"type": "child",
				"package": "sebastian/exporter@5.0.0",
				"version": "5.0.0",
				"require": {
					"key": "sebastian/exporter",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 8,
				"label": "global-state",
				"type": "child",
				"package": "sebastian/global-state@6.0.1",
				"version": "6.0.1",
				"require": {
					"key": "sebastian/global-state",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 9,
				"label": "lines-of-code",
				"type": "child",
				"package": "sebastian/lines-of-code@2.0.0",
				"version": "2.0.0",
				"require": {
					"key": "sebastian/lines-of-code",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 10,
				"label": "object-enumerator",
				"type": "child",
				"package": "sebastian/object-enumerator@5.0.0",
				"version": "5.0.0",
				"require": {
					"key": "sebastian/object-enumerator",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 11,
				"label": "object-reflector",
				"type": "child",
				"package": "sebastian/object-reflector@3.0.0",
				"version": "3.0.0",
				"require": {
					"key": "sebastian/object-reflector",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 12,
				"label": "recursion-context",
				"type": "child",
				"package": "sebastian/recursion-context@5.0.0",
				"version": "5.0.0",
				"require": {
					"key": "sebastian/recursion-context",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 13,
				"label": "type",
				"type": "child",
				"package": "sebastian/type@4.0.0",
				"version": "4.0.0",
				"require": {
					"key": "sebastian/type",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			},
			{
				"id": 14,
				"label": "version",
				"type": "child",
				"package": "sebastian/version@4.0.1",
				"version": "4.0.1",
				"require": {
					"key": "sebastian/version",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "sebastian"
			}
		]
	},
	{
		"id": 57,
		"label": "skagarwal",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "google-places-api",
				"type": "child",
				"package": "skagarwal/google-places-api@2.1.0",
				"version": "2.1.0",
				"require": {
					"key": "skagarwal/google-places-api",
					"ver": "^2.1",
					"type": "gen",
					"desc": "App"
				},
				"parent": "skagarwal"
			}
		]
	},
	{
		"id": 58,
		"label": "spatie",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "backtrace",
				"type": "child",
				"package": "spatie/backtrace@1.5.3",
				"version": "1.5.3",
				"require": {
					"key": "spatie/backtrace",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			},
			{
				"id": 1,
				"label": "cpu-load-health-check",
				"type": "child",
				"package": "spatie/cpu-load-health-check@1.0.3",
				"version": "1.0.3",
				"require": {
					"key": "spatie/cpu-load-health-check",
					"ver": "^1.0",
					"type": "gen",
					"desc": "App"
				},
				"parent": "spatie"
			},
			{
				"id": 2,
				"label": "enum",
				"type": "child",
				"package": "spatie/enum@3.13.0",
				"version": "3.13.0",
				"require": {
					"key": "spatie/enum",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			},
			{
				"id": 3,
				"label": "flare-client-php",
				"type": "child",
				"package": "spatie/flare-client-php@1.4.2",
				"version": "1.4.2",
				"require": {
					"key": "spatie/flare-client-php",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			},
			{
				"id": 4,
				"label": "ignition",
				"type": "child",
				"package": "spatie/ignition@1.10.1",
				"version": "1.10.1",
				"require": {
					"key": "spatie/ignition",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			},
			{
				"id": 5,
				"label": "laravel-analytics",
				"type": "child",
				"package": "spatie/laravel-analytics@5.0.3",
				"version": "5.0.3",
				"require": {
					"key": "spatie/laravel-analytics",
					"ver": "^5.0",
					"type": "gen",
					"desc": "App"
				},
				"parent": "spatie"
			},
			{
				"id": 6,
				"label": "laravel-health",
				"type": "child",
				"package": "spatie/laravel-health@1.23.0",
				"version": "1.23.0",
				"require": {
					"key": "spatie/laravel-health",
					"ver": "^1.22",
					"type": "gen",
					"desc": "App"
				},
				"parent": "spatie"
			},
			{
				"id": 7,
				"label": "laravel-ignition",
				"type": "child",
				"package": "spatie/laravel-ignition@2.3.0",
				"version": "2.3.0",
				"require": {
					"key": "spatie/laravel-ignition",
					"ver": "^2.0",
					"type": "dev",
					"desc": "Dev"
				},
				"parent": "spatie"
			},
			{
				"id": 8,
				"label": "laravel-package-tools",
				"type": "child",
				"package": "spatie/laravel-package-tools@1.16.1",
				"version": "1.16.1",
				"require": {
					"key": "spatie/laravel-package-tools",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			},
			{
				"id": 9,
				"label": "laravel-permission",
				"type": "child",
				"package": "spatie/laravel-permission@5.10.2",
				"version": "5.10.2",
				"require": {
					"key": "spatie/laravel-permission",
					"ver": "^5.5",
					"type": "gen",
					"desc": "App"
				},
				"parent": "spatie"
			},
			{
				"id": 10,
				"label": "laravel-robots-middleware",
				"type": "child",
				"package": "spatie/laravel-robots-middleware@1.3.2",
				"version": "1.3.2",
				"require": {
					"key": "spatie/laravel-robots-middleware",
					"ver": "^1.3",
					"type": "gen",
					"desc": "App"
				},
				"parent": "spatie"
			},
			{
				"id": 11,
				"label": "laravel-schedule-monitor",
				"type": "child",
				"package": "spatie/laravel-schedule-monitor@3.4.0",
				"version": "3.4.0",
				"require": {
					"key": "spatie/laravel-schedule-monitor",
					"ver": "^3.4",
					"type": "gen",
					"desc": "App"
				},
				"parent": "spatie"
			},
			{
				"id": 12,
				"label": "laravel-slack-alerts",
				"type": "child",
				"package": "spatie/laravel-slack-alerts@1.3.0",
				"version": "1.3.0",
				"require": {
					"key": "spatie/laravel-slack-alerts",
					"ver": "^1.1",
					"type": "gen",
					"desc": "App"
				},
				"parent": "spatie"
			},
			{
				"id": 13,
				"label": "laravel-uptime-monitor",
				"type": "child",
				"package": "spatie/laravel-uptime-monitor@4.2.1",
				"version": "4.2.1",
				"require": {
					"key": "spatie/laravel-uptime-monitor",
					"ver": "^4.2",
					"type": "gen",
					"desc": "App"
				},
				"parent": "spatie"
			},
			{
				"id": 14,
				"label": "macroable",
				"type": "child",
				"package": "spatie/macroable@2.0.0",
				"version": "2.0.0",
				"require": {
					"key": "spatie/macroable",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			},
			{
				"id": 15,
				"label": "regex",
				"type": "child",
				"package": "spatie/regex@3.1.1",
				"version": "3.1.1",
				"require": {
					"key": "spatie/regex",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			},
			{
				"id": 16,
				"label": "ssl-certificate",
				"type": "child",
				"package": "spatie/ssl-certificate@2.5.0",
				"version": "2.5.0",
				"require": {
					"key": "spatie/ssl-certificate",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			},
			{
				"id": 17,
				"label": "url",
				"type": "child",
				"package": "spatie/url@2.2.1",
				"version": "2.2.1",
				"require": {
					"key": "spatie/url",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "spatie"
			}
		]
	},
	{
		"id": 59,
		"label": "symfony",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "cache",
				"type": "child",
				"package": "symfony/cache@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/cache",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 1,
				"label": "cache-contracts",
				"type": "child",
				"package": "symfony/cache-contracts@3.3.0",
				"version": "3.3.0",
				"require": {
					"key": "symfony/cache-contracts",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 2,
				"label": "console",
				"type": "child",
				"package": "symfony/console@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/console",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 3,
				"label": "css-selector",
				"type": "child",
				"package": "symfony/css-selector@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/css-selector",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 4,
				"label": "deprecation-contracts",
				"type": "child",
				"package": "symfony/deprecation-contracts@3.3.0",
				"version": "3.3.0",
				"require": {
					"key": "symfony/deprecation-contracts",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 5,
				"label": "error-handler",
				"type": "child",
				"package": "symfony/error-handler@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/error-handler",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 6,
				"label": "event-dispatcher",
				"type": "child",
				"package": "symfony/event-dispatcher@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/event-dispatcher",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 7,
				"label": "event-dispatcher-contracts",
				"type": "child",
				"package": "symfony/event-dispatcher-contracts@3.3.0",
				"version": "3.3.0",
				"require": {
					"key": "symfony/event-dispatcher-contracts",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 8,
				"label": "finder",
				"type": "child",
				"package": "symfony/finder@6.3.3",
				"version": "6.3.3",
				"require": {
					"key": "symfony/finder",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 9,
				"label": "http-foundation",
				"type": "child",
				"package": "symfony/http-foundation@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/http-foundation",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 10,
				"label": "http-kernel",
				"type": "child",
				"package": "symfony/http-kernel@6.3.3",
				"version": "6.3.3",
				"require": {
					"key": "symfony/http-kernel",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 11,
				"label": "mailer",
				"type": "child",
				"package": "symfony/mailer@6.3.0",
				"version": "6.3.0",
				"require": {
					"key": "symfony/mailer",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 12,
				"label": "mime",
				"type": "child",
				"package": "symfony/mime@6.3.3",
				"version": "6.3.3",
				"require": {
					"key": "symfony/mime",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 13,
				"label": "polyfill-ctype",
				"type": "child",
				"package": "symfony/polyfill-ctype@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-ctype",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 14,
				"label": "polyfill-intl-grapheme",
				"type": "child",
				"package": "symfony/polyfill-intl-grapheme@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-intl-grapheme",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 15,
				"label": "polyfill-intl-idn",
				"type": "child",
				"package": "symfony/polyfill-intl-idn@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-intl-idn",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 16,
				"label": "polyfill-intl-normalizer",
				"type": "child",
				"package": "symfony/polyfill-intl-normalizer@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-intl-normalizer",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 17,
				"label": "polyfill-mbstring",
				"type": "child",
				"package": "symfony/polyfill-mbstring@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-mbstring",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 18,
				"label": "polyfill-php72",
				"type": "child",
				"package": "symfony/polyfill-php72@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-php72",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 19,
				"label": "polyfill-php80",
				"type": "child",
				"package": "symfony/polyfill-php80@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-php80",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 20,
				"label": "polyfill-php83",
				"type": "child",
				"package": "symfony/polyfill-php83@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-php83",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 21,
				"label": "polyfill-uuid",
				"type": "child",
				"package": "symfony/polyfill-uuid@1.27.0",
				"version": "1.27.0",
				"require": {
					"key": "symfony/polyfill-uuid",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 22,
				"label": "process",
				"type": "child",
				"package": "symfony/process@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/process",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 23,
				"label": "psr-http-message-bridge",
				"type": "child",
				"package": "symfony/psr-http-message-bridge@2.3.1",
				"version": "2.3.1",
				"require": {
					"key": "symfony/psr-http-message-bridge",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 24,
				"label": "routing",
				"type": "child",
				"package": "symfony/routing@6.3.3",
				"version": "6.3.3",
				"require": {
					"key": "symfony/routing",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 25,
				"label": "service-contracts",
				"type": "child",
				"package": "symfony/service-contracts@3.3.0",
				"version": "3.3.0",
				"require": {
					"key": "symfony/service-contracts",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 26,
				"label": "string",
				"type": "child",
				"package": "symfony/string@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/string",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 27,
				"label": "translation",
				"type": "child",
				"package": "symfony/translation@6.3.3",
				"version": "6.3.3",
				"require": {
					"key": "symfony/translation",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 28,
				"label": "translation-contracts",
				"type": "child",
				"package": "symfony/translation-contracts@3.3.0",
				"version": "3.3.0",
				"require": {
					"key": "symfony/translation-contracts",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 29,
				"label": "uid",
				"type": "child",
				"package": "symfony/uid@6.3.0",
				"version": "6.3.0",
				"require": {
					"key": "symfony/uid",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 30,
				"label": "var-dumper",
				"type": "child",
				"package": "symfony/var-dumper@6.3.3",
				"version": "6.3.3",
				"require": {
					"key": "symfony/var-dumper",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 31,
				"label": "var-exporter",
				"type": "child",
				"package": "symfony/var-exporter@6.3.2",
				"version": "6.3.2",
				"require": {
					"key": "symfony/var-exporter",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			},
			{
				"id": 32,
				"label": "yaml",
				"type": "child",
				"package": "symfony/yaml@6.3.3",
				"version": "6.3.3",
				"require": {
					"key": "symfony/yaml",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "symfony"
			}
		]
	},
	{
		"id": 60,
		"label": "theseer",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "tokenizer",
				"type": "child",
				"package": "theseer/tokenizer@1.2.1",
				"version": "1.2.1",
				"require": {
					"key": "theseer/tokenizer",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "theseer"
			}
		]
	},
	{
		"id": 61,
		"label": "tightenco",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "ziggy",
				"type": "child",
				"package": "tightenco/ziggy@1.6.2",
				"version": "1.6.2",
				"require": {
					"key": "tightenco/ziggy",
					"ver": "^1.0",
					"type": "gen",
					"desc": "App"
				},
				"parent": "tightenco"
			}
		]
	},
	{
		"id": 62,
		"label": "tijsverkoyen",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "css-to-inline-styles",
				"type": "child",
				"package": "tijsverkoyen/css-to-inline-styles@2.2.6",
				"version": "2.2.6",
				"require": {
					"key": "tijsverkoyen/css-to-inline-styles",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "tijsverkoyen"
			}
		]
	},
	{
		"id": 63,
		"label": "victord11",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "ssl-certification-health-check",
				"type": "child",
				"package": "victord11/ssl-certification-health-check@1.0.2",
				"version": "1.0.2",
				"require": {
					"key": "victord11/ssl-certification-health-check",
					"ver": "^1.0",
					"type": "gen",
					"desc": "App"
				},
				"parent": "victord11"
			}
		]
	},
	{
		"id": 64,
		"label": "vlucas",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "phpdotenv",
				"type": "child",
				"package": "vlucas/phpdotenv@5.5.0",
				"version": "5.5.0",
				"require": {
					"key": "vlucas/phpdotenv",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "vlucas"
			}
		]
	},
	{
		"id": 65,
		"label": "voku",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "portable-ascii",
				"type": "child",
				"package": "voku/portable-ascii@2.0.1",
				"version": "2.0.1",
				"require": {
					"key": "voku/portable-ascii",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "voku"
			}
		]
	},
	{
		"id": 66,
		"label": "webmozart",
		"type": "parent",
		"package": "parent",
		"version": "various",
		"children": [
			{
				"id": 0,
				"label": "assert",
				"type": "child",
				"package": "webmozart/assert@1.11.0",
				"version": "1.11.0",
				"require": {
					"key": "webmozart/assert",
					"ver": "n/a",
					"type": "mod",
					"desc": "Sub"
				},
				"parent": "webmozart"
			}
		]
	}
];
