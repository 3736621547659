/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Cookies from 'js-cookie';

/**
 *
 * Create axclean axios instance to handle clean network requests without a token or api key
 * No interceptors are configured on this instance
 * 
 */
const axclean = axios.create();
/**
 * 
 * Assign axclean instance to the global window variable for global application usage
 * 
 */
window.axclean = axclean;

/**
 *
 * Create axtoken axios instance to handle token requests to the back-end 
 * 
 */
const axtoken = axios.create();
/**
 * 
 * Register axtoken interceptors for sending CSRF token on axtoken requests
 * 
 */
axtoken.interceptors.request.use(async (request) => {
	const consoleLogTitle = 'resources/plugins/http.js - /axtoken/-CSRF [request] interceptor: ';
	try {
		const csrf = Cookies.get('XSRF-TOKEN');
		request.withCredentials = true;

		console.log(consoleLogTitle + '"XSRF-TOKEN" from Cookies.get(val): ' + csrf);
		console.log(consoleLogTitle + 'Current headers from axios request: ', request.headers);

		if (csrf) {
			request.headers['XSRF-TOKEN'] = encodeURI(csrf);
		}

		return request;
	} catch (err) {
		throw new Error('axtoken: Problem with request during pre-flight phase:', err);
	}
});

axtoken.interceptors.response.use(response => response, async err => {
	const consoleLogTitle = 'resources/plugins/http.js - /axtoken/-CSRF [response] interceptor: ';
	const status = get(err, 'response.status')

	if (status === 419) {
		console.log(consoleLogTitle + '419 Received: Refreshing csrf-token...');
		// Refresh our session token
		const response = await axios.get('/sanctum/csrf-cookie');

		console.log(consoleLogTitle + '419 Received: Response:', response);
		// Return a new request using the original request's configuration
		return axtoken(err.response.config)
	}

	return Promise.reject(err)
});
/**
 * 
 * Assign axtoken instance to the global window variable for global application usage
 * 
 */
window.axtoken = axtoken;

/**
 *
 * Create axauthapi axios instance to handle authenticated api requests to the back-end 
 * 
 */
const axauthapi = axios.create();
/**
 * 
 * Register axauthapi interceptors for sending CSRF token and API key on axauthapi requests
 * 
 */
axauthapi.interceptors.request.use(async (request) => {
	const consoleLogTitle = 'resources/plugins/http.js - /axkeyapi/-Auth API/CSRF [request] interceptor: ';
	try {
		const csrf = Cookies.get('XSRF-TOKEN');
		const vwAPIKey = 'x9hvcg6UBNGEqVjoCljlI5nnZFpEvfWTOmdQwOhf';
		const dashAPIKey = '6993fFVx4AghAqsS0Bt2BceARH2AkKvgkGD4QqCG';
		const apiKey = dashAPIKey;
		request.withCredentials = true;

		console.log(consoleLogTitle + '"XSRF-TOKEN" from Cookies.get(val): ' + csrf);
		console.log(consoleLogTitle + 'Configured API Key to Authenticate: ' + apiKey);
		console.log(consoleLogTitle + 'Current headers from axios request: ', request.headers);
		if (apiKey) {
			request.headers['Authorization'] = encodeURI('Bearer ' + apiKey);
		}
		if (csrf) {
			request.headers['XSRF-TOKEN'] = encodeURI(csrf);
		}

		return request;
	} catch (err) {
		throw new Error(consoleLogTitle + 'Problem with request during pre-flight phase:', err);
	}
});

/**
 * 
 * Register axauthapi interceptors for sending CSRF token and API key on axauthapi requests
 * 
 */
/*
axauthapi.interceptors.request.use(async (request) => {
	const consoleLogTitle = 'resources/plugins/http.js - /axkeyapi/-Auth API/CSRF [request] interceptor: ';
	try {
		const csrf = Cookies.get('XSRF-TOKEN');
		const vwAPIKey = 'x9hvcg6UBNGEqVjoCljlI5nnZFpEvfWTOmdQwOhf';
		const dashAPIKey = '6993fFVx4AghAqsS0Bt2BceARH2AkKvgkGD4QqCG';
		const apiKey = dashAPIKey;
		request.withCredentials = true;
		
		console.log(consoleLogTitle + '"XSRF-TOKEN" from Cookies.get(val): ' + csrf);
		console.log(consoleLogTitle + 'Configured API Key to Authenticate: ' + apiKey);
		console.log(consoleLogTitle + 'Current headers from axios request: ', request.headers);
		if (apiKey) {
			request.headers['Authorization'] = encodeURI('Bearer ' + apiKey);
		}
		if (csrf) {
			request.headers['XSRF-TOKEN'] = encodeURI(csrf);
		}

		return request;
	} catch (err) {
		throw new Error(consoleLogTitle + 'Problem with request during pre-flight phase:', err);
	}
});

axauthapi.interceptors.response.use(response => response, async err => {
	const consoleLogTitle = 'resources/plugins/http.js - /axauthapi/-CSRF [response] interceptor: ';
	const status = get(err, 'response.status')

	if (status === 419) {
		console.log(consoleLogTitle + '419 Received: Refreshing csrf-token...');
		// Refresh our session token
		const response = await axios.get('/sanctum/csrf-cookie');

		console.log(consoleLogTitle + '419 Received: Response:', response);
		// Return a new request using the original request's configuration
		return axauthapi(err.response.config)
	}

	return Promise.reject(err)
});
*/

const axauthapiRefreshAuthLogic = (failedRequest) => axauthapi.get('/sanctum/csrf-cookie').then((response) => Promise.resolve());

createAuthRefreshInterceptor(axauthapi, axauthapiRefreshAuthLogic, { statusCodes: [419] });

/**
 * 
 * Assign axauthapi instance to the global window variable for global application usage
 * 
 */
window.axauthapi = axauthapi;

/**
 *
 * Assign axios javascript library to the global window variable for global application usage
 * 
 */
window.axios = axios;
