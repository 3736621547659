import { HTTP } from "./NowDashStateDownloadCommon";

function createHttp(service, url) {
	console.log("Service: " + service)

	return {
		async retrieve(dateFiltered = false, queryParams) {
			let retrieveURL = `${url}`;

			if (dateFiltered === true) {
				retrieveURL = `${url}/${queryParams.dateStart}/${queryParams.dateEnd}`;
			}

			return HTTP.post(retrieveURL, queryParams).then(response => {
				if (response.data) {
					return response.data;
				}

				return response;
			})
		},
	}
}

export const NowDashStateAnalyticsService = createHttp('NowDashStateAnalyticsService', '/download-analytics');