import './bootstrap';
import '../css/app.css';
import '@formkit/themes/genesis';

import { createApp, h } from 'vue';
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
//import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import { plugin as fkPlugin, defaultConfig as fkDefaultConfig } from '@formkit/vue';
import VueGoogleMaps from 'vue-google-maps-community-fork';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

// Vuex
import VuexStore from './Store/VuexStore';

// Ziggy
import { ZiggyVue } from 'ziggy';
//import { Ziggy } from './ziggy';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Insights';
const mapLibraries = ['geometry', 'drawing', 'visualization', 'marker'];
const toastOptions = {
	position: 'top-right',
	autoClose: 3000,
	hideProgressBar: false,
	newestOnTop: false,
	closeOnClick: true,
	pauseOnHover: true,
	closeButton: false,
	pauseOnFocusLoss: false,
};

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({ el, app, props, plugin }) {
		return createApp({ render: () => h(app, props) })
			.use(plugin)
			.use(VuexStore)
			.use(VueGoogleMaps, {
				load: {
					key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
					libraries: mapLibraries,
				},
			})
			.use(fkPlugin, fkDefaultConfig)
			.use(Vue3Toastify, toastOptions)
			.component('InertiaLink', Link)
			.use(ZiggyVue)
			.mount(el);
	},
});

InertiaProgress.init({ color: 'linear-gradient(91.1deg,	rgba(207, 37, 40, 0.9) -2.3%, rgba(68, 33, 141, 0.9) 44.4%,	rgba(144, 252, 249, 0.9) 103.4%);' });

