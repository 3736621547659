import { npmPackagesList } from '../../../../../storage/config/versions/npm-versions.js';
import { npmPackagesTree } from '../../../../../storage/config/versions/npm-versions-tree.js';
import { cmpPackagesList } from '../../../../../storage/config/versions/cmp-versions.js';
import { cmpPackagesTree } from '../../../../../storage/config/versions/cmp-versions-tree.js';
import {
	SET_LOADING, SET_APP_VERSION
} from './InsightsStateMutations';

const consoleLogTitle = '[InsightsState.js] - ';
const pkgVersion = import.meta.env.VITE_VUE_APP_VERSION;

const InsightsState = {
	namespaced: true,
	state: {
		appVersion: pkgVersion,
		npmPkgList: npmPackagesList,
		cmpPkgList: cmpPackagesList,
		npmPkgTree: npmPackagesTree,
		cmpPkgTree: cmpPackagesTree,
		loading: false,
	},
	getters: {
		appVersion(state) {
			return state.appVersion;
		},
		npmPkgList(state) {
			return state.npmPkgList;
		},
		cmpPkgList(state) {
			return state.cmpPkgList;
		},
		npmPkgTree(state) {
			return state.npmPkgTree;
		},
		cmpPkgTree(state) {
			return state.cmpPkgTree;
		},
		loading(state) {
			return state.loading;
		},
	},
	actions: {
		async logAppVersion({ commit, state }) {
			const functionLogTitle = consoleLogTitle + 'fn/logAppVersion/ - ';
			console.log(functionLogTitle + 'Entering action...');
			console.log(functionLogTitle + '[appVersion]: ' + state.appVersion);
			//commit(SET_VW_STATES_SELECTED_CURRENT, state.liveStatesSelected);
		},

	},
	mutations: {
		[SET_LOADING](state, payload) {
			state.loading = payload;
		},
		[SET_APP_VERSION](state, payload) {
			state.appVersion = payload;
		},
	}
}

export default InsightsState;