export const cmpPackagesList = [
	{
		"package": "bacon/bacon-qr-code",
		"version": "2.0.8",
		"require": {
			"key": "bacon/bacon-qr-code",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "barryvdh/laravel-ide-helper",
		"version": "2.13.0",
		"require": {
			"key": "barryvdh/laravel-ide-helper",
			"ver": "^2.13",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "barryvdh/reflection-docblock",
		"version": "2.1.1",
		"require": {
			"key": "barryvdh/reflection-docblock",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "beyondcode/laravel-websockets",
		"version": "1.14.0",
		"require": {
			"key": "beyondcode/laravel-websockets",
			"ver": "^1.14",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "brick/math",
		"version": "0.11.0",
		"require": {
			"key": "brick/math",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "cboden/ratchet",
		"version": "0.4.4",
		"require": {
			"key": "cboden/ratchet",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "composer/class-map-generator",
		"version": "1.1.0",
		"require": {
			"key": "composer/class-map-generator",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "composer/pcre",
		"version": "3.1.0",
		"require": {
			"key": "composer/pcre",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "dasprid/enum",
		"version": "1.0.4",
		"require": {
			"key": "dasprid/enum",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "dflydev/dot-access-data",
		"version": "3.0.2",
		"require": {
			"key": "dflydev/dot-access-data",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "doctrine/cache",
		"version": "2.2.0",
		"require": {
			"key": "doctrine/cache",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "doctrine/dbal",
		"version": "3.6.6",
		"require": {
			"key": "doctrine/dbal",
			"ver": "^3.6",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "doctrine/deprecations",
		"version": "1.1.1",
		"require": {
			"key": "doctrine/deprecations",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "doctrine/event-manager",
		"version": "2.0.0",
		"require": {
			"key": "doctrine/event-manager",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "doctrine/inflector",
		"version": "2.0.8",
		"require": {
			"key": "doctrine/inflector",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "doctrine/lexer",
		"version": "3.0.0",
		"require": {
			"key": "doctrine/lexer",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "dragonmantank/cron-expression",
		"version": "3.3.3",
		"require": {
			"key": "dragonmantank/cron-expression",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "egulias/email-validator",
		"version": "4.0.1",
		"require": {
			"key": "egulias/email-validator",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "evenement/evenement",
		"version": "3.0.2",
		"require": {
			"key": "evenement/evenement",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "facade/ignition-contracts",
		"version": "1.0.2",
		"require": {
			"key": "facade/ignition-contracts",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "fakerphp/faker",
		"version": "1.23.0",
		"require": {
			"key": "fakerphp/faker",
			"ver": "^1.9.1",
			"type": "dev",
			"desc": "Dev"
		}
	},
	{
		"package": "fig/http-message-util",
		"version": "1.1.5",
		"require": {
			"key": "fig/http-message-util",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "filp/whoops",
		"version": "2.15.3",
		"require": {
			"key": "filp/whoops",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "firebase/php-jwt",
		"version": "6.8.1",
		"require": {
			"key": "firebase/php-jwt",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "fruitcake/php-cors",
		"version": "1.2.0",
		"require": {
			"key": "fruitcake/php-cors",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "google/analytics-data",
		"version": "0.9.5",
		"require": {
			"key": "google/analytics-data",
			"ver": "^0.9.2",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "google/auth",
		"version": "1.29.1",
		"require": {
			"key": "google/auth",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "google/common-protos",
		"version": "4.3.0",
		"require": {
			"key": "google/common-protos",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "google/gax",
		"version": "1.22.1",
		"require": {
			"key": "google/gax",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "google/grpc-gcp",
		"version": "0.3.0",
		"require": {
			"key": "google/grpc-gcp",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "google/longrunning",
		"version": "0.2.6",
		"require": {
			"key": "google/longrunning",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "google/protobuf",
		"version": "3.24.1",
		"require": {
			"key": "google/protobuf",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "graham-campbell/guzzle-factory",
		"version": "5.1.2",
		"require": {
			"key": "graham-campbell/guzzle-factory",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "graham-campbell/result-type",
		"version": "1.1.1",
		"require": {
			"key": "graham-campbell/result-type",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "grpc/grpc",
		"version": "1.57.0",
		"require": {
			"key": "grpc/grpc",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "guzzlehttp/guzzle",
		"version": "7.7.0",
		"require": {
			"key": "guzzlehttp/guzzle",
			"ver": "^7.2",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "guzzlehttp/promises",
		"version": "2.0.1",
		"require": {
			"key": "guzzlehttp/promises",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "guzzlehttp/psr7",
		"version": "2.6.0",
		"require": {
			"key": "guzzlehttp/psr7",
			"ver": "^2.4",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "guzzlehttp/uri-template",
		"version": "1.0.1",
		"require": {
			"key": "guzzlehttp/uri-template",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "hamcrest/hamcrest-php",
		"version": "2.0.1",
		"require": {
			"key": "hamcrest/hamcrest-php",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "haruncpi/laravel-user-activity",
		"version": "dev-l10-php8-human",
		"require": {
			"key": "haruncpi/laravel-user-activity",
			"ver": "dev-l10-php8-human",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "inertiajs/inertia-laravel",
		"version": "0.6.9",
		"require": {
			"key": "inertiajs/inertia-laravel",
			"ver": "^0.6.3",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "jaybizzle/crawler-detect",
		"version": "1.2.116",
		"require": {
			"key": "jaybizzle/crawler-detect",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "jenssegers/agent",
		"version": "2.6.4",
		"require": {
			"key": "jenssegers/agent",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "laravel/fortify",
		"version": "1.17.5",
		"require": {
			"key": "laravel/fortify",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "laravel/framework",
		"version": "10.20.0",
		"require": {
			"key": "laravel/framework",
			"ver": "^10.0",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "laravel/horizon",
		"version": "5.19.1",
		"require": {
			"key": "laravel/horizon",
			"ver": "^5.10",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "laravel/jetstream",
		"version": "2.16.2",
		"require": {
			"key": "laravel/jetstream",
			"ver": "^2.12",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "laravel/pint",
		"version": "1.11.0",
		"require": {
			"key": "laravel/pint",
			"ver": "^1.0",
			"type": "dev",
			"desc": "Dev"
		}
	},
	{
		"package": "laravel/prompts",
		"version": "0.1.6",
		"require": {
			"key": "laravel/prompts",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "laravel/sail",
		"version": "1.23.4",
		"require": {
			"key": "laravel/sail",
			"ver": "^1.0.1",
			"type": "dev",
			"desc": "Dev"
		}
	},
	{
		"package": "laravel/sanctum",
		"version": "3.2.6",
		"require": {
			"key": "laravel/sanctum",
			"ver": "^3.2",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "laravel/serializable-closure",
		"version": "1.3.1",
		"require": {
			"key": "laravel/serializable-closure",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "laravel/slack-notification-channel",
		"version": "2.5.0",
		"require": {
			"key": "laravel/slack-notification-channel",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "laravel/telescope",
		"version": "4.16.1",
		"require": {
			"key": "laravel/telescope",
			"ver": "^4.13",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "laravel/tinker",
		"version": "2.8.1",
		"require": {
			"key": "laravel/tinker",
			"ver": "^2.7",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "league/commonmark",
		"version": "2.4.0",
		"require": {
			"key": "league/commonmark",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "league/config",
		"version": "1.2.0",
		"require": {
			"key": "league/config",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "league/flysystem",
		"version": "3.15.1",
		"require": {
			"key": "league/flysystem",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "league/flysystem-local",
		"version": "3.15.0",
		"require": {
			"key": "league/flysystem-local",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "league/mime-type-detection",
		"version": "1.13.0",
		"require": {
			"key": "league/mime-type-detection",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "lorisleiva/cron-translator",
		"version": "0.4.4",
		"require": {
			"key": "lorisleiva/cron-translator",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "masterminds/html5",
		"version": "2.8.1",
		"require": {
			"key": "masterminds/html5",
			"ver": "^2.7",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "mobiledetect/mobiledetectlib",
		"version": "2.8.41",
		"require": {
			"key": "mobiledetect/mobiledetectlib",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "mockery/mockery",
		"version": "1.6.6",
		"require": {
			"key": "mockery/mockery",
			"ver": "^1.4.4",
			"type": "dev",
			"desc": "Dev"
		}
	},
	{
		"package": "monolog/monolog",
		"version": "3.4.0",
		"require": {
			"key": "monolog/monolog",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "myclabs/deep-copy",
		"version": "1.11.1",
		"require": {
			"key": "myclabs/deep-copy",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "nesbot/carbon",
		"version": "2.69.0",
		"require": {
			"key": "nesbot/carbon",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "nette/schema",
		"version": "1.2.4",
		"require": {
			"key": "nette/schema",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "nette/utils",
		"version": "4.0.1",
		"require": {
			"key": "nette/utils",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "nikic/php-parser",
		"version": "4.17.1",
		"require": {
			"key": "nikic/php-parser",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "nunomaduro/collision",
		"version": "7.8.1",
		"require": {
			"key": "nunomaduro/collision",
			"ver": "^7.0",
			"type": "dev",
			"desc": "Dev"
		}
	},
	{
		"package": "nunomaduro/termwind",
		"version": "1.15.1",
		"require": {
			"key": "nunomaduro/termwind",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "opcodesio/log-viewer",
		"version": "2.5.5",
		"require": {
			"key": "opcodesio/log-viewer",
			"ver": "^2.0",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "paragonie/constant_time_encoding",
		"version": "2.6.3",
		"require": {
			"key": "paragonie/constant_time_encoding",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "paragonie/random_compat",
		"version": "9.99.100",
		"require": {
			"key": "paragonie/random_compat",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "paragonie/sodium_compat",
		"version": "1.20.0",
		"require": {
			"key": "paragonie/sodium_compat",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phar-io/manifest",
		"version": "2.0.3",
		"require": {
			"key": "phar-io/manifest",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phar-io/version",
		"version": "3.2.1",
		"require": {
			"key": "phar-io/version",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpdocumentor/reflection-common",
		"version": "2.2.0",
		"require": {
			"key": "phpdocumentor/reflection-common",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpdocumentor/type-resolver",
		"version": "1.7.3",
		"require": {
			"key": "phpdocumentor/type-resolver",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpoption/phpoption",
		"version": "1.9.1",
		"require": {
			"key": "phpoption/phpoption",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpstan/phpdoc-parser",
		"version": "1.23.1",
		"require": {
			"key": "phpstan/phpdoc-parser",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpunit/php-code-coverage",
		"version": "10.1.3",
		"require": {
			"key": "phpunit/php-code-coverage",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpunit/php-file-iterator",
		"version": "4.0.2",
		"require": {
			"key": "phpunit/php-file-iterator",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpunit/php-invoker",
		"version": "4.0.0",
		"require": {
			"key": "phpunit/php-invoker",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpunit/php-text-template",
		"version": "3.0.0",
		"require": {
			"key": "phpunit/php-text-template",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpunit/php-timer",
		"version": "6.0.0",
		"require": {
			"key": "phpunit/php-timer",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "phpunit/phpunit",
		"version": "10.3.2",
		"require": {
			"key": "phpunit/phpunit",
			"ver": "^10.0",
			"type": "dev",
			"desc": "Dev"
		}
	},
	{
		"package": "pragmarx/google2fa",
		"version": "8.0.1",
		"require": {
			"key": "pragmarx/google2fa",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/cache",
		"version": "3.0.0",
		"require": {
			"key": "psr/cache",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/clock",
		"version": "1.0.0",
		"require": {
			"key": "psr/clock",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/container",
		"version": "2.0.2",
		"require": {
			"key": "psr/container",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/event-dispatcher",
		"version": "1.0.0",
		"require": {
			"key": "psr/event-dispatcher",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/http-client",
		"version": "1.0.2",
		"require": {
			"key": "psr/http-client",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/http-factory",
		"version": "1.0.2",
		"require": {
			"key": "psr/http-factory",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/http-message",
		"version": "1.1",
		"require": {
			"key": "psr/http-message",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/log",
		"version": "3.0.0",
		"require": {
			"key": "psr/log",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psr/simple-cache",
		"version": "3.0.0",
		"require": {
			"key": "psr/simple-cache",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "psy/psysh",
		"version": "0.11.20",
		"require": {
			"key": "psy/psysh",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "pusher/pusher-php-server",
		"version": "7.2.3",
		"require": {
			"key": "pusher/pusher-php-server",
			"ver": "^7.2",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "raiym/instagram-php-scraper",
		"version": "dev-main",
		"require": {
			"key": "raiym/instagram-php-scraper",
			"ver": "dev-main",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "ralouphie/getallheaders",
		"version": "3.0.3",
		"require": {
			"key": "ralouphie/getallheaders",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "ramsey/collection",
		"version": "2.0.0",
		"require": {
			"key": "ramsey/collection",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "ramsey/uuid",
		"version": "4.7.4",
		"require": {
			"key": "ramsey/uuid",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "ratchet/rfc6455",
		"version": "0.3.1",
		"require": {
			"key": "ratchet/rfc6455",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "react/cache",
		"version": "1.2.0",
		"require": {
			"key": "react/cache",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "react/dns",
		"version": "1.11.0",
		"require": {
			"key": "react/dns",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "react/event-loop",
		"version": "1.4.0",
		"require": {
			"key": "react/event-loop",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "react/http",
		"version": "1.9.0",
		"require": {
			"key": "react/http",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "react/promise",
		"version": "3.0.0",
		"require": {
			"key": "react/promise",
			"ver": "^3.0",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "react/socket",
		"version": "1.14.0",
		"require": {
			"key": "react/socket",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "react/stream",
		"version": "1.3.0",
		"require": {
			"key": "react/stream",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "ringcentral/psr7",
		"version": "1.3.0",
		"require": {
			"key": "ringcentral/psr7",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/cli-parser",
		"version": "2.0.0",
		"require": {
			"key": "sebastian/cli-parser",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/code-unit",
		"version": "2.0.0",
		"require": {
			"key": "sebastian/code-unit",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/code-unit-reverse-lookup",
		"version": "3.0.0",
		"require": {
			"key": "sebastian/code-unit-reverse-lookup",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/comparator",
		"version": "5.0.1",
		"require": {
			"key": "sebastian/comparator",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/complexity",
		"version": "3.0.0",
		"require": {
			"key": "sebastian/complexity",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/diff",
		"version": "5.0.3",
		"require": {
			"key": "sebastian/diff",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/environment",
		"version": "6.0.1",
		"require": {
			"key": "sebastian/environment",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/exporter",
		"version": "5.0.0",
		"require": {
			"key": "sebastian/exporter",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/global-state",
		"version": "6.0.1",
		"require": {
			"key": "sebastian/global-state",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/lines-of-code",
		"version": "2.0.0",
		"require": {
			"key": "sebastian/lines-of-code",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/object-enumerator",
		"version": "5.0.0",
		"require": {
			"key": "sebastian/object-enumerator",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/object-reflector",
		"version": "3.0.0",
		"require": {
			"key": "sebastian/object-reflector",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/recursion-context",
		"version": "5.0.0",
		"require": {
			"key": "sebastian/recursion-context",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/type",
		"version": "4.0.0",
		"require": {
			"key": "sebastian/type",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "sebastian/version",
		"version": "4.0.1",
		"require": {
			"key": "sebastian/version",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "skagarwal/google-places-api",
		"version": "2.1.0",
		"require": {
			"key": "skagarwal/google-places-api",
			"ver": "^2.1",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/backtrace",
		"version": "1.5.3",
		"require": {
			"key": "spatie/backtrace",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "spatie/cpu-load-health-check",
		"version": "1.0.3",
		"require": {
			"key": "spatie/cpu-load-health-check",
			"ver": "^1.0",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/enum",
		"version": "3.13.0",
		"require": {
			"key": "spatie/enum",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "spatie/flare-client-php",
		"version": "1.4.2",
		"require": {
			"key": "spatie/flare-client-php",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "spatie/ignition",
		"version": "1.10.1",
		"require": {
			"key": "spatie/ignition",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "spatie/laravel-analytics",
		"version": "5.0.3",
		"require": {
			"key": "spatie/laravel-analytics",
			"ver": "^5.0",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/laravel-health",
		"version": "1.23.0",
		"require": {
			"key": "spatie/laravel-health",
			"ver": "^1.22",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/laravel-ignition",
		"version": "2.3.0",
		"require": {
			"key": "spatie/laravel-ignition",
			"ver": "^2.0",
			"type": "dev",
			"desc": "Dev"
		}
	},
	{
		"package": "spatie/laravel-package-tools",
		"version": "1.16.1",
		"require": {
			"key": "spatie/laravel-package-tools",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "spatie/laravel-permission",
		"version": "5.10.2",
		"require": {
			"key": "spatie/laravel-permission",
			"ver": "^5.5",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/laravel-robots-middleware",
		"version": "1.3.2",
		"require": {
			"key": "spatie/laravel-robots-middleware",
			"ver": "^1.3",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/laravel-schedule-monitor",
		"version": "3.4.0",
		"require": {
			"key": "spatie/laravel-schedule-monitor",
			"ver": "^3.4",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/laravel-slack-alerts",
		"version": "1.3.0",
		"require": {
			"key": "spatie/laravel-slack-alerts",
			"ver": "^1.1",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/laravel-uptime-monitor",
		"version": "4.2.1",
		"require": {
			"key": "spatie/laravel-uptime-monitor",
			"ver": "^4.2",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "spatie/macroable",
		"version": "2.0.0",
		"require": {
			"key": "spatie/macroable",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "spatie/regex",
		"version": "3.1.1",
		"require": {
			"key": "spatie/regex",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "spatie/ssl-certificate",
		"version": "2.5.0",
		"require": {
			"key": "spatie/ssl-certificate",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "spatie/url",
		"version": "2.2.1",
		"require": {
			"key": "spatie/url",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/cache",
		"version": "6.3.2",
		"require": {
			"key": "symfony/cache",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/cache-contracts",
		"version": "3.3.0",
		"require": {
			"key": "symfony/cache-contracts",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/console",
		"version": "6.3.2",
		"require": {
			"key": "symfony/console",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/css-selector",
		"version": "6.3.2",
		"require": {
			"key": "symfony/css-selector",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/deprecation-contracts",
		"version": "3.3.0",
		"require": {
			"key": "symfony/deprecation-contracts",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/error-handler",
		"version": "6.3.2",
		"require": {
			"key": "symfony/error-handler",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/event-dispatcher",
		"version": "6.3.2",
		"require": {
			"key": "symfony/event-dispatcher",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/event-dispatcher-contracts",
		"version": "3.3.0",
		"require": {
			"key": "symfony/event-dispatcher-contracts",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/finder",
		"version": "6.3.3",
		"require": {
			"key": "symfony/finder",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/http-foundation",
		"version": "6.3.2",
		"require": {
			"key": "symfony/http-foundation",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/http-kernel",
		"version": "6.3.3",
		"require": {
			"key": "symfony/http-kernel",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/mailer",
		"version": "6.3.0",
		"require": {
			"key": "symfony/mailer",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/mime",
		"version": "6.3.3",
		"require": {
			"key": "symfony/mime",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-ctype",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-ctype",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-intl-grapheme",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-intl-grapheme",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-intl-idn",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-intl-idn",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-intl-normalizer",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-intl-normalizer",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-mbstring",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-mbstring",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-php72",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-php72",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-php80",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-php80",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-php83",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-php83",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/polyfill-uuid",
		"version": "1.27.0",
		"require": {
			"key": "symfony/polyfill-uuid",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/process",
		"version": "6.3.2",
		"require": {
			"key": "symfony/process",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/psr-http-message-bridge",
		"version": "2.3.1",
		"require": {
			"key": "symfony/psr-http-message-bridge",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/routing",
		"version": "6.3.3",
		"require": {
			"key": "symfony/routing",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/service-contracts",
		"version": "3.3.0",
		"require": {
			"key": "symfony/service-contracts",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/string",
		"version": "6.3.2",
		"require": {
			"key": "symfony/string",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/translation",
		"version": "6.3.3",
		"require": {
			"key": "symfony/translation",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/translation-contracts",
		"version": "3.3.0",
		"require": {
			"key": "symfony/translation-contracts",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/uid",
		"version": "6.3.0",
		"require": {
			"key": "symfony/uid",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/var-dumper",
		"version": "6.3.3",
		"require": {
			"key": "symfony/var-dumper",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/var-exporter",
		"version": "6.3.2",
		"require": {
			"key": "symfony/var-exporter",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "symfony/yaml",
		"version": "6.3.3",
		"require": {
			"key": "symfony/yaml",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "theseer/tokenizer",
		"version": "1.2.1",
		"require": {
			"key": "theseer/tokenizer",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "tightenco/ziggy",
		"version": "1.6.2",
		"require": {
			"key": "tightenco/ziggy",
			"ver": "^1.0",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "tijsverkoyen/css-to-inline-styles",
		"version": "2.2.6",
		"require": {
			"key": "tijsverkoyen/css-to-inline-styles",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "victord11/ssl-certification-health-check",
		"version": "1.0.2",
		"require": {
			"key": "victord11/ssl-certification-health-check",
			"ver": "^1.0",
			"type": "gen",
			"desc": "App"
		}
	},
	{
		"package": "vlucas/phpdotenv",
		"version": "5.5.0",
		"require": {
			"key": "vlucas/phpdotenv",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "voku/portable-ascii",
		"version": "2.0.1",
		"require": {
			"key": "voku/portable-ascii",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	},
	{
		"package": "webmozart/assert",
		"version": "1.11.0",
		"require": {
			"key": "webmozart/assert",
			"ver": "n/a",
			"type": "mod",
			"desc": "Sub"
		}
	}
];
