export const SET_ANALYTICS_READY = 'SET_ANALYTICS_READY';
export const SET_DASH_DATA = 'SET_DASH_DATA';
export const SET_DASH_READY = 'SET_DASH_READY';
export const SET_RANK_DATA = 'SET_RANK_DATA';
export const SET_RANK_READY = 'SET_RANK_READY';

export const SET_KIOSK_DATA = 'SET_KIOSK_DATA';
export const SET_KIOSK_COUNTS = 'SET_KIOSK_COUNTS';

export const SET_KIOSK_MAP_MARKERS = 'SET_KIOSK_MAP_MARKERS';

export const SET_KIOSK_MAP_HEAT_DATA = 'SET_KIOSK_MAP_HEAT_DATA';
export const SET_KIOSK_MAP_HEAT_OPTIONS = 'SET_KIOSK_MAP_HEAT_OPTIONS';

export const SET_KIOSK_MAP_INFO_WINDOWS = 'SET_KIOSK_MAP_INFO_WINDOWS';

export const SET_DEALER_DETAILS = 'SET_DEALER_DETAILS';

export const SET_DEALER_TABLE_DATA = 'SET_DEALER_TABLE_DATA';

export const SET_DEALERS_GROUPED_BY_STATE = 'SET_DEALERS_GROUPED_BY_STATE';

export const SET_DEALERS_KEYED_BY_STATE = 'SET_DEALERS_KEYED_BY_STATE';

export const SET_VW_STATE_LIST = 'SET_VW_STATE_LIST';
export const SET_VW_STATE_COUNTS = 'SET_VW_STATE_COUNTS';

export const SET_VW_AREA_LIST = 'SET_VW_AREA_LIST';
export const SET_VW_AREA_OPTIONS = 'SET_VW_AREA_OPTIONS';

export const SET_VW_REGION_LIST = 'SET_VW_REGION_LIST';
export const SET_VW_REGION_OPTIONS = 'SET_VW_REGION_OPTIONS';

export const SET_VW_STATES_SELECTED_CURRENT = 'SET_VW_STATES_SELECTED_CURRENT';
export const SET_VW_STATES_SELECTED_LIVE = 'SET_VW_STATES_SELECTED_LIVE';

export const SET_VW_AREAS_SELECTED_CURRENT = 'SET_VW_AREAS_SELECTED_CURRENT';
export const SET_VW_AREAS_SELECTED_LIVE = 'SET_VW_AREAS_SELECTED_LIVE';

export const SET_VW_REGIONS_SELECTED_CURRENT = 'SET_VW_REGIONS_SELECTED_CURRENT';
export const SET_VW_REGIONS_SELECTED_LIVE = 'SET_VW_REGIONS_SELECTED_LIVE';

export const SET_DATA_FILTER_MODEL_CURRENT = 'SET_DATA_FILTER_MODEL_CURRENT';
export const SET_DATA_FILTER_MODEL_LIVE = 'SET_DATA_FILTER_MODEL_LIVE';

export const SET_DATA_ROWS_SELECTED_CURRENT = 'SET_DATA_ROWS_SELECTED_CURRENT';
export const SET_DATA_ROWS_SELECTED_LIVE = 'SET_DATA_ROWS_SELECTED_LIVE';

export const SET_DATA_NODES_SELECTED_CURRENT = 'SET_DATA_NODES_SELECTED_CURRENT';
export const SET_DATA_NODES_SELECTED_LIVE = 'SET_DATA_NODES_SELECTED_LIVE';

export const SET_DATA_ROWS_FILTERED_CURRENT = 'SET_DATA_ROWS_FILTERED_CURRENT';
export const SET_DATA_ROWS_FILTERED_LIVE = 'SET_DATA_ROWS_FILTERED_LIVE';

export const SET_FILTER_DATES_SELECTED = 'SET_FILTER_DATES_SELECTED';
export const SET_FILTER_ACCOUNTS_SELECTED = 'SET_FILTER_ACCOUNTS_SELECTED';

export const SET_IS_ANALYTICS_DATA_DIRTY = 'SET_IS_ANALYTICS_DATA_DIRTY';

export const SET_NOW_DASH_TOOLTIPS = 'SET_NOW_DASH_TOOLTIPS';
export const SET_NOW_DASH_DEFAULT_TOOLTIP = 'SET_NOW_DASH_DEFAULT_TOOLTIP';

export const SET_LOADING = 'SET_LOADING';

export const SET_HEADERS = 'SET_HEADERS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_ITEMS = 'SET_ITEMS';
export const PATCH_ITEM = 'PATCH_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const CREATE_ITEM = 'CREATE_ITEM';
export const SET_SEARCH = 'SET_SEARCH';

export const SET_GOOGLE_DATA_EXPORT_NAME = 'SET_GOOGLE_DATA_EXPORT_NAME';
export const SET_GOOGLE_DATA = 'SET_GOOGLE_DATA';
export const SET_DEALER_PAGE_VIEWS = 'SET_DEALER_PAGE_VIEWS';
export const SET_DEALER_PAGE_VIEWS_COL_DEFS = 'SET_DEALER_PAGE_VIEWS_COL_DEFS';
export const SET_AG_GOOGLE_DATA_COL_DEFS = 'SET_AG_GOOGLE_DATA_COL_DEFS';
export const SET_SHOW_GOOGLE_DATA_MODAL = 'SET_SHOW_GOOGLE_DATA_MODAL';
export const SET_GOOGLE_DATA_DEALERS = 'SET_GOOGLE_DATA_DEALERS';
export const SET_GOOGLE_DATA_DEALER_FILTERS = 'SET_GOOGLE_DATA_DEALER_FILTERS';
export const CLEAR_GOOGLE_DATA_DEALER_FILTERS = 'CLEAR_GOOGLE_DATA_DEALER_FILTERS';