/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import Cookies from 'js-cookie';

window.Pusher = Pusher;
window.Echo = Echo;
window.dashEcho = false;

const consoleLogTitle = 'resources/plugins/echo.js: ';

const csrf = Cookies.get('XSRF-TOKEN');
window.XSRF = encodeURI(csrf);

console.log(consoleLogTitle + '"XSRF-TOKEN" from Cookies.get(val): ' + csrf);

const dashEchoConfig = {
	echoInstance: false,
	channels: [],
	dynamicFunctions: [],
	realtimeConfig: false,
	builtBroadcast: false,
	broadcastSetupComplete: false,
	presentKiosks: [],
};

const channelJoin = {
	updatePresentKiosks: function (users) {
		if (dashEchoConfig.builtBroadcast === true) {
			dashEchoConfig.presentKiosks = users;
		}
	},
	removeUser: function (user) {
		let currentUsers = dashEchoConfig.presentKiosks;
		currentUsers = currentUsers.filter((u) => u.id !== user.id);
		channelJoin.updatePresentKiosks(currentUsers);
	},
	joinPresence: function (channelName, channelEvents) {
		const functionLogTitle = consoleLogTitle + 'function [channelJoin.presence] - ';
		console.log(functionLogTitle + 'Channel Name: ' + channelName);
		const logChannelName = 'presence-' + channelName;
		const channelInstance = window.dashEcho
			.join(channelName)
			.here((users) => {
				console.log(logChannelName + ': Event Received - HERE==================');
				channelJoin.updatePresentKiosks(users);
				console.log(logChannelName + ': - users: ', users);
				console.log(logChannelName + ': End Event Data=========================');
			})
			.joining((user) => {
				console.log(logChannelName + ': Event Received - JOINING===============');
				let currentUsers = dashEchoConfig.presentKiosks;
				currentUsers.push(user);
				channelJoin.updatePresentKiosks(currentUsers);
				console.log(logChannelName + ': - user: ', user);
				console.log(logChannelName + ': End Event Data=========================');
			})
			.leaving((user) => {
				console.log(logChannelName + ': Event Received - LEAVING===============');
				channelJoin.removeUser(user);
				console.log(logChannelName + ': - user: ', user);
				console.log(logChannelName + ': End Event Data=========================');
			});
		console.log(functionLogTitle + 'Result of operation:', channelInstance);
	},
	joinPublic: function (channelName, channelEvents) {
		const functionLogTitle = consoleLogTitle + 'function [channelJoin.public] - ';
		console.log(functionLogTitle + 'Channel Name: ' + channelName);
		const channelInstance = window.dashEcho.channel(channelName);
		if (channelEvents.length > 0) {
			for (const channelEvent of channelEvents) {
				console.log(functionLogTitle + ' - ' + channelName + ' - channelEvent:', channelEvent);
				channelInstance.listen(channelEvent.event, (event) => {
					const prettyEventName = String(channelEvent.event).trim().slice(1);
					console.log(
						channelName + ': Event Received - ' + prettyEventName + '========================='
					);
					console.log(channelName + ': Event Data:', event);
					if (event.message) {
						const eventMessage = String(event.message).trim().toLowerCase();
						console.log(channelName + ': Event Message:', eventMessage);
						if (channelEvent.messages) {
							if (channelEvent.messages.length > 0) {
								for (const messageProcess of channelEvent.messages) {
									if (messageProcess.message === eventMessage) {
										const messageFunction = new Function(
											messageProcess.arguments,
											messageProcess.function
										);
										messageFunction(event, eventMessage, dynamicFunctions);
									}
								}
							}
						}
					}
					console.log(
						channelName + ': End Event Data - ' + prettyEventName + '========================='
					);
				});
			}
		}
		console.log(functionLogTitle + 'Result of operation:', channelInstance);
	},
	joinPrivate: function (channelName, channelEvents) {
		const functionLogTitle = consoleLogTitle + 'function [channelJoin.private] - ';
		console.log(functionLogTitle + 'Channel Name: ' + channelName);
		const channelInstance = window.dashEcho.private(channelName);
		if (channelEvents.length > 0) {
			for (const channelEvent of channelEvents) {
				console.log(functionLogTitle + ' - ' + channelName + ' - channelEvent:', channelEvent);
				channelInstance.listen(channelEvent.event, (event) => {
					const prettyEventName = String(channelEvent.event).trim().slice(1);
					console.log(
						channelName + ': Event Received - ' + prettyEventName + '========================='
					);
					console.log(channelName + ': Event Data:', event);
					if (event.message) {
						const eventMessage = String(event.message).trim().toLowerCase();
						console.log(channelName + ': Event Message:', eventMessage);
						if (channelEvent.messages) {
							if (channelEvent.messages.length > 0) {
								for (const messageProcess of channelEvent.messages) {
									if (messageProcess.message === eventMessage) {
										const messageFunction = new Function(
											messageProcess.arguments,
											messageProcess.function
										);
										messageFunction(event, eventMessage, dynamicFunctions);
									}
								}
							}
						}
					}
					console.log(
						channelName + ': End Event Data - ' + prettyEventName + '========================='
					);
				});
			}
		}
		console.log(functionLogTitle + 'Result of operation:', channelInstance);
	},
};

const getRealtimeConfig = async (realtimeConfigURL, configCallback) => {
	const functionLogTitle = consoleLogTitle + 'getRealtimeConfig(realtimeConfigURL, configCallback) AJAX Request: ';
	console.log(functionLogTitle + '==========================');
	console.log(functionLogTitle + 'Make call to Insights API endpoint to retrieve realtime configuration data...');
	console.log(functionLogTitle + 'realtimeConfigURL: ' + realtimeConfigURL);
	console.log(functionLogTitle + '==========================');
	//const realtimeConfigBaseInfo = ajaxGetRemoteRequestsHostInfo();
	//const realtimeConfigURL = realtimeConfig.url + '/api/config/vw/kiosk/realtime';
	try {
		const axiosURL = realtimeConfigURL;
		console.log(functionLogTitle + 'Axios AJAX Request - Begin with URL: ' + axiosURL + '...');
		var axConfig = {
			method: 'get',
			url: axiosURL,
			headers: {
				'Content-Type': 'application/json',
			},
		};
		const response = await window.axauthapi(axConfig);
		console.log(functionLogTitle + 'Response from Realtime Config API call: ', response);
		if (response.data) {
			console.log(functionLogTitle + 'Response.Data from Realtime Config API call: ', response.data);
			configCallback(response.data);
		}
	} catch (err) {
		console.error(functionLogTitle + 'ERROR - Problems in the retrieval of the Realtime Config:', err);
		configCallback(false);
	}
};

const echoKey = 'JZH5ub7kfqPkluVGpgGL7wtR3hoYHDlMRtNYkZU2';

window.echoKey = echoKey;

let nonStandardPort = false;

const currentPort = window.location.port;
if (currentPort !== '80' && currentPort !== '443') {
	nonStandardPort = true;
}

const currentURL = window.location.protocol + '//' + window.location.host;
const currentWeb = currentURL + (nonStandardPort === true ? ':' + currentPort : '')

//const currentURL = 'https://insights.libby.lan';
const currentHost = window.location.hostname;
//const currentHost = 'insights.libby.lan';
const echoRemoteInfo = {
	host: currentHost,
	url: currentURL,
	web: currentWeb,
};

const echoConfig = {
	options: {
		broadcaster: 'pusher',
		key: import.meta.env.VITE_PUSHER_APP_KEY, //'e1addfbc2bd5f895a71a',
		host: echoRemoteInfo.host,
		//authEndpoint: echoRemoteInfo.url + '/dash/broadcasting/auth',
		authEndpoint: echoRemoteInfo.url + '/broadcasting/auth',
		wsHost: echoRemoteInfo.host,
		wsPort: 26001,
		wssPort: 26001,
		forceTLS: true,
		encrypted: true,
		disableStats: true,
		cluster: 'us1',
		enabledTransports: ['ws', 'wss'],
		auth: {
			headers: {
				Accept: 'application/json',
				//'XSRF-TOKEN': window.XSRF,
				//Authorization: `Bearer ${echoKey}`,
				//'X-DASH-AUTH': `${echoKey}`,
				//'X-DASH-USER': 'matthew.libby@spectrio.com',
			},
		},
	},
	echoInstance: false,
	channels: [],
	dynamicFunctions: [],
	realtimeConfig: false,
	configURL: echoRemoteInfo.url + '/api/config/dash/web/realtime',
};

const reloadDashWebPage = () => {
	window.location.reload();
}

let dynamicFunctions = {
	dyn: {
		reloadWebPage: reloadDashWebPage,
	},
};

const buildEchoChannels = () => {
	const functionLogTitle = consoleLogTitle + 'buildEchoChannels() - ';
	if (dashEchoConfig.builtBroadcast === true) {
		const echoChannels = dashEchoConfig.channels;
		const echoChannelEvents = dashEchoConfig.realtimeConfig.channelEvents;
		for (const channel of echoChannels) {
			const joinFunctions = {
				presence: 'joinPresence',
				public: 'joinPublic',
				private: 'joinPrivate',
			};
			const channelName = channel.name;
			const channelType = channel.type;
			let channelEvents = [];
			if (echoChannelEvents) {
				for (const channelEvent of echoChannelEvents) {
					if (channelName.includes(channelEvent.name)) {
						channelEvents = channelEvent.listen;
					}
				}
			}
			console.log(functionLogTitle + 'Post-check for configured channel events:', channelEvents);
			channelJoin[joinFunctions[channelType]](channelName, channelEvents);
		}
		dashEchoConfig.broadcastSetupComplete = true;
	}

}

const buildEchoInstance = () => {
	if (echoConfig.options) {
		if (echoConfig.options !== null) {
			const dashEcho = new Echo(echoConfig.options);
			window.dashEcho = dashEcho;
			dashEchoConfig.echoInstance = dashEcho;
			dashEchoConfig.builtBroadcast = true;
			buildEchoChannels();
		}
	}
}

const buildRealtimeConfig = (configData) => {
	const functionLogTitle = consoleLogTitle + 'buildRealtimeCallback(configData) AJAX Request: ';
	console.log(functionLogTitle + '==========================');
	console.log(functionLogTitle + 'Made call to Insights API endpoint, data returned...');
	console.log(functionLogTitle + 'realtimeConfig: ', configData);
	console.log(functionLogTitle + '==========================');
	if (configData !== false) {
		const checkJSON = JSON.parse(JSON.stringify(configData));
		if (checkJSON) {
			if (configData.dynamicFunctions) {
				if (configData.dynamicFunctions.length > 0) {
					const buildFunctions = {};
					for (const dynFunction of configData.dynamicFunctions) {
						buildFunctions[dynFunction.name] = new Function(
							dynFunction.function.arguments,
							dynFunction.function.body
						).bind(dynamicFunctions);
					}
					dynamicFunctions = { ...dynamicFunctions, ...buildFunctions };
					dashEchoConfig.dynamicFunctions = buildFunctions;
				}
			}
			if (configData.channelsToJoin) {
				if (configData.channelsToJoin.length > 0) {
					const channelArray = [];
					for (const channel of configData.channelsToJoin) {
						const channelName = String(channel.name);
						/*
							.replace('{account}', kioskIdentity.kioskAccount)
							.replace('{id}', kioskIdentity.kioskID)
							.replace('{user}', kioskIdentity.kioskUser)
							.replace('{device}', kioskIdentity.kioskDevice);
						*/
						const channelType = String(channel.type);
						const channelObj = {
							name: channelName,
							type: channelType,
						};
						channelArray.push(channelObj);
					}
					if (channelArray.length > 0) {
						dashEchoConfig.channels = channelArray;
					}
				}
			}
			dashEchoConfig.realtimeConfig = configData;
			buildEchoInstance();
		}
	}
}

getRealtimeConfig(echoConfig.configURL, buildRealtimeConfig);

/*
window.Echo = new Echo({
	broadcaster: 'pusher',
	key: 'e1addfbc2bd5f895a71a',
	wsHost: 'insights.libby.lan',
	wssPort: 29443,
	forceTLS: true,
	encrypted: true,
	disableStats: true,
	cluster: 'us1',
	enabledTransports: ['wss'],
	auth: {
		headers: {
			Authorization: `Bearer ${echoKey}`,
		},
	},
});

window.Echo.join('presence-vw-kiosk')
	.listenToAll((event, data) => {
		console.log('Presence-Feeds: Event Received=========================');
		console.log('event: ', event);
		console.log('data : ', data);
		console.log('Presence-Feeds: End Event Data=========================');
	});

window.Echo.channel('global-vw-kiosk')
	.listenToAll((event, data) => {
		console.log('Global-VW-Kiosk: Event Received=========================');
		console.log('event: ', event);
		console.log('data : ', data);
		console.log('Global-VW-Kiosk: End Event Data=========================');
	});

*/
