import { createStore, createLogger } from 'vuex'
import VuexPersistence from 'vuex-persist';
import localforage from 'localforage';
import _ from 'lodash';
import NowDashState from './VuexModules/NowDashState/NowDashState';
import OtherDashState from './VuexModules/OtherDashState/OtherDashState';
import InsightsState from './VuexModules/InsightsState/InsightsState';
//import { FilterJobQueue as filterqueue } from './VuexModules/FilterJobQueue/FilterJobQueue';

const debug = process.env.NODE_ENV !== 'production';

localforage.config({
	driver: [
		localforage.WEBSQL,
		localforage.INDEXEDDB,
		localforage.LOCALSTORAGE
	],
	name: 'Insights-Forage',
	storeName: 'dashboard_state',
	version: '1.0',
	description: 'Persisted state for Telemetry and Analytics Summary Dashboards'
});

const vuexPersist = new VuexPersistence({
	key: 'insights-dashboard',
	storage: localforage,
	asyncStorage: true,
	supportCircular: true,
	reducer: (state) => ({ dashstate: _.cloneDeep(state.dashstate) }),
	//modules: [OtherDashState],
});

export default createStore({
	modules: {
		nowstate: NowDashState,
		dashstate: OtherDashState,
		insights: InsightsState,
		//filterqueue,
	},
	strict: debug,
	plugins: debug ? [createLogger(), vuexPersist.plugin] : [vuexPersist.plugin]
})